import { createServer } from "miragejs";
import appConfig from "configs/app.config";
import {
  projectList,
  scrumboardData,
  issueData,
  projectDashboardData,
} from "./data/projectData";
import { usersData, adminsData } from "./data/usersData";
import {
  portfolioData,
  myWalletsList,
  exchanges,
  currencies,
  walletsData,
  marketData,
  marketDataKeys,
  transactionHistoryData,
  walletTransactions,
  cryptoDashboardData,
} from "./data/cryptoData";
import { myBots, logs } from "./data/botsData";
import { templates } from "./data/templatesData";

import {
  usersFakeApi,
  accountFakeApi,
  cryptoFakeApi,
  authFakeApi,
  botsFakeApi,
  templatesFakeApi,
} from "./fakeApi";

const { apiPrefix } = appConfig;

export default function mockServer({ environment = "test" }) {
  return createServer({
    environment,
    seeds(server) {
      server.db.loadData({
        projectList,
        scrumboardData,
        issueData,
        usersData,
        adminsData,
        portfolioData,
        myWalletsList,
        exchanges,
        currencies,
        myBots,
        logs,
        templates,
        walletsData,
        marketData,
        marketDataKeys,
        transactionHistoryData,
        walletTransactions,
        projectDashboardData,
        cryptoDashboardData,
      });
    },
    routes() {
      // this.passthrough((request) => {
      //   let isExternal = request.url.includes("bots");
      //   return isExternal;
      // });
      usersFakeApi(this, apiPrefix);
      accountFakeApi(this, apiPrefix);
      authFakeApi(this, apiPrefix);
      cryptoFakeApi(this, apiPrefix);
      botsFakeApi(this, apiPrefix);
      templatesFakeApi(this, apiPrefix);
    },
  });
}
