import paginate from "utils/paginate";
import { v4 as uuidv4 } from "uuid";

export default function botsFakeApi(server, apiPrefix) {
  server.get(`${apiPrefix}/bots/me`, (schema) => {
    return schema.db.myBots;
  });
  server.post(`${apiPrefix}/bots`, (schema, { requestBody }) => {
    const body = JSON.parse(requestBody);
    const createdBot = {
      id: uuidv4(),
      name: body.name,
      cards: [],
      state: "DRAFT",
    };
    schema.db.myBots.insert(createdBot);
    return createdBot;
  });
  server.get(`${apiPrefix}/bots/:id`, (schema, request) => {
    const botId = request.params.id;

    const botDetails = schema.db.myBots.find(botId);
    return botDetails;
  });

  server.post(`${apiPrefix}/bots/logs`, (schema, { requestBody }) => {
    const body = JSON.parse(requestBody);
    const { page, size } = body;
    let data = schema.db.logs;
    let total = data.length;

    const logsPages = paginate(data, size, page);

    const responseData = {
      total_records: total,
      page_number: page,
      page_size: size,
      logs: logsPages,
    };
    return responseData;
  });
}
