import { Response } from "miragejs";
import uniqueId from "lodash/uniqueId";
import isEmpty from "lodash/isEmpty";

export default function authFakeApi(server, apiPrefix) {
  server.post(`${apiPrefix}/users/auth`, (schema, { requestBody }) => {
    const { username, password } = JSON.parse(requestBody);
    const user = schema.db.usersData.findBy({
      username,
      password,
    });
    if (user?.token) {
      return { access_token: user.token, refresh_token: user.token };
    }
    return new Response(
      401,
      { some: "header" },
      { message: "Invalid email or password!" }
    );
  });

  server.post(`${apiPrefix}/admin/auth`, (schema, { requestBody }) => {
    const { username, password } = JSON.parse(requestBody);
    const user = schema.db.adminsData.findBy({
      username,
      password,
    });
    if (user?.token) {
      return { access_token: user.token, refresh_token: user.token };
    }
    return new Response(
      401,
      { some: "header" },
      { message: "Invalid email or password!" }
    );
  });
  server.post(`${apiPrefix}/sign-out`, () => {
    return true;
  });

  server.post(`${apiPrefix}/users/create`, (schema, { requestBody }) => {
    const { username, password, email } = JSON.parse(requestBody);
    const userExist = schema.db.usersData.findBy({
      username,
    });
    const emailUsed = schema.db.usersData.findBy({ email });
    const newUser = {
      avatar: "/img/avatars/thumb-1.jpg",
      name: "Abdellah Naji",
      username,
      email,
      authority: "user",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    };
    if (!isEmpty(userExist)) {
      const errors = [{ message: "", domain: "global", reason: "invalid" }];
      return new Response(
        400,
        { some: "header" },
        { errors, message: "User already exist!" }
      );
    }

    if (!isEmpty(emailUsed)) {
      const errors = [{ message: "", domain: "global", reason: "invalid" }];
      return new Response(
        400,
        { some: "header" },
        { errors, message: "Email already used" }
      );
    }

    schema.db.usersData.insert({
      ...newUser,
      ...{ id: uniqueId("user_"), password, accountUserName: username },
    });
    return {
      user: newUser,
      token: "wVYrxaeNa9OxdnULvde1Au5m5w63",
    };
  });

  server.post(`${apiPrefix}/forgot-password`, () => {
    return true;
  });

  server.post(`${apiPrefix}/reset-password`, () => {
    return true;
  });
}
