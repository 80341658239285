export const APP_NAME = "Trabo";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
export const TOKEN_STORE_NAME = "token";
export const REFRESH_TOKEN_STORE_NAME = "refresh_token";
export const SUBSCRIPTION_STORE_NAME = "subscription_details";
export const TEMPLATES_STORE_NAME = "templates";
export const MODE = "mode";
export const CURRENCIES_STORE_NAME = "currencies";
export const CUSTOM_URLS_CALLS_STORE_NAME = "custom_urls_responses";
