import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "auth/session",
  initialState: {
    signedIn: false,
    isAdmin: false,
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.isAdmin = action.payload;
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false;
    },
    rememberUsername: (state, action) => {
      state.username = action.payload;
    },
  },
});

export const { onSignInSuccess, onSignOutSuccess, rememberUsername } =
  sessionSlice.actions;

export default sessionSlice.reducer;
