export const templates = [
  {
    id: "045462c6-d63e-4538-b791-e084140c7dd2",
    title: "Price",
    description: "get the price of a ticker",
    form: [
      {
        id: "a0182eb7-ec9a-4e1c-b510-80b8b4c2e61a",
        key: "ticker",
        label: "Ticker",
        options: [],
        type: "STRING",
      },
      {
        id: "a09bcf2a-356e-4579-bd14-05e6fc8aa104",
        key: "exchange",
        label: "Exchange",
        options: [
          {
            id: "19395e34-cb20-421f-a039-6dd085f71406",
            key: "Binance",
            label: "Binance",
          },
          {
            id: "25c70892-066a-4a60-8f38-0e96b615709d",
            key: "Bybit",
            label: "Bybit",
          },
          {
            id: "d66a4f13-e048-4d35-b186-e5c79d5bc52e",
            key: "Ftx",
            label: "Ftx",
          },
        ],
        type: "STRING",
      },
    ],
    fetcherName: "price",
    templateType: "INPUT",
    templateCategory: "PRICES",
    outputType: "FLOAT",
    frequancy: 30,
  },
  {
    id: "410d6535-fee3-45b1-bb1b-9427c8441ba3",
    title: "Fear & Greed Index",
    description: "get Index of feer and greed of the market",
    form: [
      {
        id: "751fd094-7fcc-4227-8e80-a781cf11e553",
        key: "days",
        label: "Days",
        options: [],
        type: "INTEGER",
      },
    ],
    fetcherName: "fear&greed",
    templateType: "INPUT",
    templateCategory: "FONDAMONTAL_INDICATORS",
    outputType: "FLOAT",
    frequancy: 86400,
  },
  {
    id: "0d7eaeea-f7af-4ad0-b8af-72c4628e5c93",
    title: "News",
    description: "get the price of a ticker",
    form: [
      {
        id: "40c98183-7ac8-4bce-9ae5-b02e40fbcbde",
        key: "subject",
        label: "Subject",
        options: [],
        type: "STRING",
      },
      {
        id: "4c7eaf8a-80b6-47f5-9298-6fa3fce16874",
        key: "fieldNumber",
        label: "Field Number",
        options: [
          {
            id: "523d0daf-c9e4-4422-8cd7-f3651d7df093",
            key: "0",
            label: "author",
          },
          {
            id: "6a230335-dd52-4f6d-96b6-e8760eb6ae54",
            key: "1",
            label: "description",
          },
          {
            id: "4c391be2-20f2-4daf-af4d-00174fb17258",
            key: "2",
            label: "title",
          },
          {
            id: "d647f621-e52f-42a9-a6e7-fe89b48f4eae",
            key: "3",
            label: "content",
          },
        ],
        type: "INTEGER",
      },
      {
        id: "2e6c4bcf-5d85-4caa-aeb1-1f71ff2d8a7e",
        key: "deltaDays",
        label: "Delta Days",
        options: [],
        type: "STRING",
      },
    ],
    fetcherName: "news",
    templateType: "INPUT",
    templateCategory: "FONDAMONTAL_INDICATORS",
    outputType: "STRING",
    frequancy: 86400,
  },
  {
    id: "ec53f63d-9a25-422a-bf28-a92ea11630c3",
    title: "Volume",
    description: "get the price of a ticker",
    form: [
      {
        id: "14cfd35f-1a4b-440c-b085-f65fe03054ee",
        key: "ticker",
        label: "Ticker",
        options: [],
        type: "STRING",
      },
      {
        id: "f751d684-b87c-4215-9541-1ca117986a82",
        key: "exchange",
        label: "Exchange",
        options: [
          {
            id: "74d77767-7423-4e0d-b829-29c2899285da",
            key: "Binance",
            label: "Binance",
          },
          {
            id: "f7d7d85c-53b5-4cda-876e-eac60dd49741",
            key: "Bybit",
            label: "Bybit",
          },
          {
            id: "0351c743-ea2e-4881-8a06-ae451dc11183",
            key: "Ftx",
            label: "Ftx",
          },
        ],
        type: "STRING",
      },
      {
        id: "1aaeead8-f871-407a-8819-0b1b58cd1130",
        key: "intervalInt",
        label: "Interval Int",
        options: [],
        type: "INTEGER",
      },
      {
        id: "54f8bec6-ca70-4948-9a1e-0bf83acef6a5",
        key: "intervalChar",
        label: "Interval Char",
        options: [],
        type: "STRING",
      },
    ],
    fetcherName: "volume",
    templateType: "INPUT",
    templateCategory: "TECHNICAL_INDICATORS",
    outputType: "FLOAT",
    frequancy: 20,
  },
  {
    id: "c168c249-80c2-4bae-a2b9-ffde4115db83",
    title: "AND",
    description: "the logical AND operation",
    form: [
      {
        id: "b680758e-1f30-4c4e-be10-10f10bde73a7",
        key: "a",
        label: "A",
        options: [],
        type: "BOOLEAN",
      },
      {
        id: "317821c3-04e4-4117-b58c-9b6355caca6b",
        key: "b",
        label: "B",
        options: [],
        type: "BOOLEAN",
      },
    ],
    fetcherName: "and_",
    templateType: "OPERATION",
    templateCategory: "BASIC_LOGICAL_OPERATIONS",
    outputType: "BOOLEAN",
    frequancy: null,
  },
  {
    id: "4af00d31-cd0f-44c7-a26a-794dfe7b873a",
    title: "Contains",
    description:
      "if only one of the list of strings contain a string it return true",
    form: [
      {
        id: "6e1e9738-a812-44ad-9d06-b100a14c8439",
        key: "list_strings",
        label: "List of words",
        options: [],
        type: "STRING",
      },
      {
        id: "d1995d39-e403-4d9a-877e-c1329ad0bcec",
        key: "to_find",
        label: "Word to find",
        options: [],
        type: "STRING",
      },
    ],
    fetcherName: "contains",
    templateType: "OPERATION",
    templateCategory: "BASIC_TEXT_OPERATIONS",
    outputType: "BOOLEAN",
    frequancy: null,
  },
  {
    id: "2f1fee06-f969-489c-a0bc-95f8c1d03525",
    title: "superior",
    description: "if a > b  returns true",
    form: [
      {
        id: "6a5e5eb0-b12a-4fe9-9401-9e719e31da46",
        key: "a",
        label: "A",
        options: [],
        type: "FLOAT",
      },
      {
        id: "23be691b-1568-4cc9-a497-65396ced5cc1",
        key: "b",
        label: "B",
        options: [],
        type: "FLOAT",
      },
    ],
    fetcherName: "superior",
    templateType: "OPERATION",
    templateCategory: "BASIC_LOGICAL_OPERATIONS",
    outputType: "BOOLEAN",
    frequancy: null,
  },
  {
    id: "e51008af-73ea-4220-8ea7-860050616483",
    title: "notify",
    description: "send email if condition",
    form: [
      {
        id: "297076b5-741d-4735-8923-375f79d44148",
        key: "to",
        label: "To",
        options: [],
        type: "STRING",
      },
      {
        id: "fb99e047-0994-4fa8-b4b3-d614a0b2aacf",
        key: "condition",
        label: "Condition",
        options: [],
        type: "BOOLEAN",
      },
      {
        id: "3e4658de-0b5e-4497-a6f4-99a30d579be0",
        key: "subject",
        label: "Subject",
        options: [],
        type: "STRING",
      },
      {
        id: "fc2a76e5-7b67-490b-bba9-7bcbf1fd5953",
        key: "message",
        label: "Message",
        options: [],
        type: "STRING",
      },
    ],
    fetcherName: "notify",
    templateType: "ACTION",
    templateCategory: "BASIC_ACTION",
    outputType: "BOOLEAN",
    frequancy: null,
  },
  {
    id: "e5100efefefefrrkw8ea7-860050616483",
    title: "Load options test",
    description: "Custom input",
    form: [
      {
        id: "297076b5-741d-4735-8923-375f79d44148",
        key: "myExchanges",
        label: "My Exchanges",
        options: [],
        endpoint: "/wallets/options",
        type: "STRING",
      },
    ],
    fetcherName: "test1",
    templateType: "ACTION",
    templateCategory: "BASIC_ACTION",
    outputType: "BOOLEAN",
    frequancy: null,
  },
  {
    id: "e5kekeke-ekeje-kw8ea7-86083",
    title: "Dynamic select",
    description: "dynamic input",
    form: [
      {
        id: "297we6b5-741dwe-8dsdw79d44148",
        key: "type",
        label: "Market Type",
        options: [],
        endpoint: "/market/keys",
        type: "STRING",
      },
      {
        id: "297we6b5-741dwe-8dswdwd222224148",
        key: "value",
        label: "Market Value",
        options: [],
        endpoint: "/market/{type}/list",
        type: "STRING",
      },
    ],
    fetcherName: "test2",
    templateType: "ACTION",
    templateCategory: "BASIC_ACTION",
    outputType: "BOOLEAN",
    frequancy: null,
  },
];
