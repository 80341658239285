export const usersData = [
  {
    id: "1",
    name: "Abdellah Naji",
    email: "abdo123@gmail.com",
    img: "/img/avatars/thumb-3.jpg",
    username: "abdo123",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "2",
    name: "Terrance Moreno",
    email: "terrance_moreno@infotech.io",
    img: "/img/avatars/thumb-2.jpg",
    username: "abdo2",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "3",
    name: "Carolyn Perkins",
    email: "eileen_h@hotmail.com",
    img: "/img/avatars/thumb-1.jpg",
    username: "abdo3",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "4",
    name: "Luke Cook",
    email: "cookie_lukie@hotmail.com",
    img: "/img/avatars/thumb-4.jpg",
    username: "abdo4",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "5",
    name: "Joyce Freeman",
    email: "joyce991@infotech.io",
    img: "/img/avatars/thumb-5.jpg",
    username: "abdo5",
    status: "pending",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "6",
    name: "Samantha Phillips",
    email: "samanthaphil@infotech.io",
    img: "/img/avatars/thumb-6.jpg",
    username: "abdo6",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "7",
    name: "Tara Fletcher",
    email: "taratarara@imaze.edu.du",
    img: "/img/avatars/thumb-7.jpg",
    username: "abdo7",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "8",
    name: "Frederick Adams",
    email: "iamfred@imaze.infotech.io",
    img: "/img/avatars/thumb-8.jpg",
    username: "abdo8",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "9",
    name: "Carolyn Hanson",
    email: "carolyn_h@gmail.com",
    img: "/img/avatars/thumb-9.jpg",
    username: "abdo9",
    status: "pending",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "10",
    name: "Brittany Hale",
    email: "brittany1134@gmail.com",
    img: "/img/avatars/thumb-10.jpg",
    username: "abdo10",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "11",
    name: "Lloyd Obrien",
    email: "handsome-obrien@hotmail.com",
    img: "/img/avatars/thumb-11.jpg",
    username: "abdo11",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "12",
    name: "Gabriella May",
    email: "maymaymay12@infotech.io",
    img: "/img/avatars/thumb-12.jpg",
    username: "abdo12",
    status: "blocked",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "13",
    name: "Lee Wheeler",
    email: "lee_wheeler@infotech.io",
    img: "/img/avatars/thumb-13.jpg",
    username: "abdo13",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "14",
    name: "Gail Barnes",
    email: "gailby0116@infotech.io",
    img: "/img/avatars/thumb-14.jpg",
    username: "abdo14",
    status: "blocked",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
  {
    id: "15",
    name: "Ella Robinson",
    email: "ella_robinson@infotech.io",
    img: "/img/avatars/thumb-15.jpg",
    username: "abdo15",
    status: "active",
    password: "12345678",
    location: "New York, US",
    birthday: "10/10/1992",
    phoneNumber: "+12-123-1234",
    role: "user",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWJkZWxsYWggTmFqaSIsImlhdCI6MTUxNjIzOTAyMiwic3ViIjoiYWJkbzEyMyIsImF1dGhvcml0eSI6InVzZXIiLCJlbWFpbCI6ImFiZG8xMjNAZ21haWwuY29tIiwidXNlcklkIjoiN2Y1MDI5ODgtNTk5Ny00MDY0LWI4NDAtZWE0OTBjYTA0ZjljIn0.thFMkE7vkpmke7HSEMZUH2Mza2q5QMhhj1zuPygg3-A",
    orderHistory: [
      {
        id: "#36223",
        item: "Mock premium pack",
        status: "pending",
        amount: 39.9,
        date: 1639132800,
      },
      {
        id: "#34283",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1636790880,
      },
      {
        id: "#32234",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1634090880,
      },
      {
        id: "#31354",
        item: "Business board pro subscription",
        status: "paid",
        amount: 59.9,
        date: 1631532800,
      },
    ],
    subscription: [
      {
        plan: "Business board pro",
        status: "active",
        billing: "monthly",
        nextPaymentDate: 1639132800,
        amount: 59.9,
      },
    ],
  },
];

export const adminsData = [
  {
    username: "trabo",
    password: "12345678",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVHJhYm8gQWRtaW4iLCJpYXQiOjE1MTYyMzkwMjIsInN1YiI6InRyYWJvIiwiYXV0aG9yaXR5IjoiYWRtaW4iLCJlbWFpbCI6InRyYWJvQGdtYWlsLmNvbSIsInVzZXJJZCI6IjdmNTAyOTg4LTU5OTctNDA2NC1iODQwLWVhZWhoZWhlZjljIn0.6CDjD_4bMYXvXsOrlUQ-L6i7Jcpi33NL60Eq4vc-4Pk",
  },
];
