import sortBy from "utils/sortBy";
import paginate from "utils/paginate";
import wildCardSearch from "utils/wildCardSearch";
import { v4 as uuidv4 } from "uuid";

export default function cryptoFakeApi(server, apiPrefix) {
  server.get(`${apiPrefix}/wallets/me`, (schema) => {
    return schema.db.myWalletsList;
  });
  server.get(`${apiPrefix}/wallets/options`, (schema) => {
    return schema.db.myWalletsList?.map((wallet) => {
      return {
        key: wallet.key,
        label: wallet.name,
      };
    });
  });
  server.get(`${apiPrefix}/market/keys`, (schema) => {
    return schema.db.marketDataKeys[0].list?.map((key) => {
      return {
        key,
        label: key,
      };
    });
  });
  server.get(`${apiPrefix}/market/:type/list`, (schema, request) => {
    const type = request.params.type;
    return schema.db.marketData[0][type].map((element) => {
      return {
        key: element.symbol,
        label: element.name,
      };
    });
  });
  server.post(`${apiPrefix}/wallets`, (schema, { requestBody }) => {
    const body = JSON.parse(requestBody);
    const exchange =
      body.exchange_id && schema.db.exchanges.find(body.exchange_id);
    const createdWallet = {
      id: uuidv4(),
      name: body.name,
      is_virtual: body.is_virtual,
      exchange: exchange,
      key_id: null,
      key: body.api_key,
      balance: null,
      currency: body.currency,
      user_id: "0332aa7e-8896-4f9a-8e59-c20f86303f29",
      strategies: [],
      created_at: "2023-06-03T19:46:36.322508",
    };
    schema.db.myWalletsList.insert(createdWallet);
    return createdWallet;
  });
  server.put(`${apiPrefix}/wallets`, (schema, { requestBody }) => {
    // const body = JSON.parse(requestBody);
    // // const exchange =
    // //   body.exchange_id && schema.db.exchanges.find(body.exchange_id);
    return {};
  });

  server.get(`${apiPrefix}/exchanges`, (schema) => {
    return schema.db.exchanges;
  });
  server.get(`${apiPrefix}/exchanges/quotes/:exchangeId`, (schema) => {
    return schema.db.currencies[0].list;
  });
  server.get(`${apiPrefix}/crypto/dashboard`, (schema) => {
    return schema.db.cryptoDashboardData[0];
  });

  server.get(`${apiPrefix}/crypto/portfolio`, (schema) => {
    return schema.db.portfolioData[0];
  });

  server.get(`${apiPrefix}/crypto/wallets`, (schema) => {
    return schema.db.walletsData;
  });

  server.get(`${apiPrefix}/orders/paginate/me`, (schema, request) => {
    const { page, page_size, wallet, bot } = request.queryParams;

    let data = [];
    if (!wallet && !bot) {
      data = schema.db.walletTransactions;
    } else {
      const params = {};

      if (!!wallet) {
        params.wallet_id = wallet;
      }
      if (!!bot) {
        params.bot_id = bot;
      }
      data = schema.db.walletTransactions.where(params);
    }
    let total = data.length;

    data = paginate(data, page_size, page);

    return {
      data,
      total,
    };
  });

  server.post(`${apiPrefix}/crypto/market`, (schema, { requestBody }) => {
    const { tab, pageIndex, pageSize, sort, query } = JSON.parse(requestBody);

    let data = schema.db.marketData[0][tab];
    let total = data.length;
    const { order, key } = sort;

    if (key && order) {
      if (key !== "action") {
        data.sort(sortBy(key, order === "desc", parseInt));
      } else {
        data.sort(sortBy(key, order === "desc", (a) => a.toUpperCase()));
      }
    }

    if (query) {
      data = wildCardSearch(data, query);
      total = data.length;
    }

    data = paginate(data, pageSize, pageIndex);

    return {
      data,
      total,
    };
  });
}
