import React from "react";
import NumberFormat from "react-number-format";
import { Input } from "components/ui";

const NumberInput = ({ inputSuffix, inputPrefix, ...props }) => {
  return (
    <Input
      {...props}
      value={props.value}
      suffix={inputSuffix}
      prefix={inputPrefix}
    />
  );
};

const NumberFormatInput = ({ onValueChange, form, field, ...rest }) => {
  return (
    <NumberFormat
      customInput={NumberInput}
      onValueChange={onValueChange}
      form={form}
      field={field}
      onBlur={field?.onBlur}
      {...rest}
    />
  );
};

const FormNumericInput = ({
  form,
  field,
  inputSuffix,
  inputPrefix,
  onValueChange,
  ...rest
}) => {
  return (
    <NumberFormatInput
      form={form}
      field={field}
      inputPrefix={inputPrefix}
      inputSuffix={inputSuffix}
      onValueChange={onValueChange}
      {...rest}
    />
  );
};

export default FormNumericInput;
