import React, { forwardRef, useMemo, useRef, useEffect } from "react";
import { Table, Pagination, Select, Checkbox } from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const {
    indeterminate,
    onChange,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    ...rest
  } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return (
    <Checkbox
      className="mb-0"
      ref={resolvedRef}
      onChange={(_, e) => handleChange(e)}
      {...rest}
    />
  );
});

const DataTable = ({
  skeletonAvatarColumns,
  columns = [],
  data = [],
  loading = false,
  onCheckBoxChange,
  onIndeterminateCheckBoxChange,
  onPaginationChange,
  onSelectChange,
  onSort,
  pageSizes = [10, 25, 50, 100],
  selectable = false,
  skeletonAvatarProps,
  pagingData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  autoResetSelectedRows = true,
}) => {
  const { pageSize, pageIndex, total } = pagingData;

  const pageSizeOption = useMemo(
    () =>
      pageSizes.map((number) => ({ value: number, label: `${number} / page` })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        manualPagination: true,
        manualSortBy: true,
        autoResetSelectedRows,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        if (selectable) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: (props) => (
                <div>
                  <IndeterminateCheckbox
                    {...props.getToggleAllRowsSelectedProps()}
                    onIndeterminateCheckBoxChange={(e) =>
                      handleIndeterminateCheckBoxChange(
                        e.target.checked,
                        props.rows
                      )
                    }
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    onCheckBoxChange={(e) =>
                      handleCheckBoxChange(e.target.checked, row.original)
                    }
                  />
                </div>
              ),
              sortable: false,
            },
            ...columns,
          ]);
        }
      }
    );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  const handleSort = (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
      const sortOrder = isSortedDesc ? "desc" : "asc";
      toggleSortBy(!isSortedDesc);
      onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };
  const { tableKey, ...tableProps } = getTableProps();

  return (
    <Loading loading={loading && data.length !== 0} type="cover">
      <Table key={tableKey} {...tableProps}>
        <THead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
            return (
              <Tr key={key} {...headerProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...columnProps } = column.getHeaderProps();
                  return (
                    <Th key={key} {...columnProps}>
                      {column.render("Header") &&
                        (column.sortable ? (
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSort(column)}
                          >
                            {column.render("Header")}
                            <span>
                              <Sorter sort={column.isSortedDesc} />
                            </span>
                          </div>
                        ) : (
                          <div>{column.render("Header")}</div>
                        ))}
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </THead>
        {loading && data.length === 0 ? (
          <TableRowSkeleton
            columns={columns.length}
            rows={pagingData.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();
              return (
                <Tr key={key} {...rowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...cellProps } = cell.getCellProps();
                    return (
                      <Td key={key} {...cellProps}>
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </TBody>
        )}
      </Table>
      <div className="flex items-center justify-between mt-4">
        <Pagination
          pageSize={pageSize}
          currentPage={pageIndex}
          total={total}
          onChange={handlePaginationChange}
        />
        <div style={{ minWidth: 130 }}>
          <Select
            size="sm"
            menuPlacement="top"
            isSearchable={false}
            value={pageSizeOption.filter((option) => option.value === pageSize)}
            options={pageSizeOption}
            onChange={(option) => handleSelectChange(option.value)}
          />
        </div>
      </div>
    </Loading>
  );
};

export default DataTable;
