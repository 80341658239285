import { forwardRef } from "react";
import classNames from "classnames";

const Container = forwardRef(
  ({ className, children, asElement: Component = "div", ...rest }, ref) => {
    return (
      <Component
        ref={ref}
        className={classNames("container mx-auto", className)}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

export default Container;
