import ApiService from "./ApiService";

export const REFRESH_TOKEN_ENDPOINT = "/user/refresh-token";
export const OPEN_ENDPOINTS = [
  "/user/auth",
  "/admin/auth",
  "public/contactus",
  "/user/requestotp",
  "/user/resetforgotpassword",
  "/user/email-verification/",
];

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/user/auth",
    method: "post",
    data,
  });
}

export async function apiRefreshToken() {
  return ApiService.fetchData({
    url: REFRESH_TOKEN_ENDPOINT,
    method: "get",
  });
}

export async function apiVerifyEmail(id) {
  return ApiService.fetchData({
    url: `/user/email-verification/${id}`,
    method: "get",
  });
}

export async function apiResendVerificationEmail(id) {
  return ApiService.fetchData({
    url: `users/resend-email-verification/${id}`,
    method: "get",
  });
}

export async function apiAdminSignIn(data) {
  return ApiService.fetchData({
    url: "/admin/auth",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/user",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  // return ApiService.fetchData({
  //   url: "/sign-out",
  //   method: "post",
  //   data,
  // });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/user/requestotp",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/user/resetforgotpassword",
    method: "post",
    data,
  });
}

export async function apiContactUs(data) {
  return ApiService.fetchData({
    url: "/public/contactus",
    method: "post",
    data,
  });
}
