import ApiService from "./ApiService";

export async function apiGetTemplates() {
  return ApiService.fetchData({
    url: "/templates",
    method: "get",
  });
}

export async function customApiCall(url) {
  return ApiService.fetchData({
    url,
    method: "get",
  });
}

export async function apiGetInputTypes() {
  return ApiService.fetchData({
    url: "input-types",
    method: "get",
  });
}
