export const currencies = {
  list: [
    "TRY",
    "BRL",
    "DAI",
    "VAI",
    "XRP",
    "TUSD",
    "EUR",
    "PLN",
    "ZAR",
    "UST",
    "UAH",
    "IDRT",
    "USDP",
    "ETH",
    "BIDR",
    "PAX",
    "BNB",
    "DOGE",
    "USDS",
    "BKRW",
    "USDC",
    "NGN",
    "AUD",
    "ARS",
    "BUSD",
    "USDT",
    "BVND",
    "DOT",
    "GBP",
    "BTC",
    "TRX",
    "RON",
    "RUB",
  ],
};

export const exchanges = [
  {
    id: "binance",
    name: "Binance",
    image:
      "https://th.bing.com/th/id/OIP.44Cwv2gZWxVGWAa2XQaieQHaE8?pid=ImgDet&rs=1",
  },
  {
    id: "kucoin",
    name: "Kucoin",
    image: "https://fortunez.com/wp-content/uploads/kucoin-logo.jpg",
  },
];
export const myWalletsList = [
  {
    id: "f29cf1c5-34b1-4d76-8942-78a529213dbb",
    is_virtual: false,
    name: "WAlletX",
    exchange: {
      id: "binance",
      name: "Binance",
      image:
        "https://th.bing.com/th/id/OIP.44Cwv2gZWxVGWAa2XQaieQHaE8?pid=ImgDet&rs=1",
    },
    key_id: null,
    key: "Qhekq46Es1jh92dQQisAq662SmxELLLsHHe4YWrH",
    balance: null,
    currency: "USDT",
    user_id: "0332aa7e-8896-4f9a-8e59-c20f86303f29",
    strategies: [],
    created_at: "2023-06-01T19:20:06.906586",
  },
  {
    id: "80e49cca-1724-44d0-9617-dbda294f665e",
    is_virtual: false,
    name: "Wallet2",
    exchange: {
      id: "kucoin",
      name: "Kucoin",
      image: "https://fortunez.com/wp-content/uploads/kucoin-logo.jpg",
    },
    key_id: null,
    key: "HekoehqLqoelelekQQisAq662SmxELLLsHHe4YWrH",
    balance: 900,
    currency: "GBP",
    user_id: "0332aa7e-8896-4f9a-8e59-c20f86303f29",
    strategies: [],
    created_at: "2023-06-01T19:24:32.186656",
  },
  {
    id: "jekeleeklel7bda294f665e",
    is_virtual: true,
    name: "Virtual Wallet",
    key_id: null,
    user_id: "0332aa7e-8896-4f9a-8e59-c20f86303f29",
    strategies: [],
    created_at: "2023-06-01T19:24:32.186656",
  },
];
export const portfolioData = {
  assetsData: {
    labels: ["Bitcoin", "Ethereum", "Solana"],
    values: [15032, 11246, 8273],
    coinValues: [0.5832112, 1.7294746, 196.9766],
    coinSymbol: ["BTC", "ETH", "SOL"],
  },
  accountValueData: {
    netValue: 45512.72,
    coinValue: 34551.4,
    cashBalance: 10961.32,
  },
  overviewData: {
    series: [
      {
        name: "Bitcoin",
        data: [
          16375, 18954, 16869, 19569, 17381, 18981, 21403, 18902, 20244, 19706,
        ],
      },
      {
        name: "Ethereum",
        data: [
          10689, 12646, 11420, 13520, 11655, 13826, 13092, 13805, 12560, 13993,
        ],
      },
      {
        name: "Solana",
        data: [8163, 8921, 8337, 9614, 9063, 9998, 9041, 10224, 9332, 10379],
      },
    ],
    date: [
      "01 Jan",
      "02 Jan",
      "03 Jan",
      "04 Jan",
      "05 Jan",
      "06 Jan",
      "07 Jan",
      "08 Jan",
      "09 Jan",
      "10 Jan",
      "11 Jan",
      "12 Jan",
    ],
  },
  recentAcivity: [
    {
      date: "June 3, 2022",
      data: [
        {
          coinValue: 0.0383522,
          fiatValue: 1161.5,
          symbol: "BTC",
          curency: "USD",
          action: "Buy BTC",
          actionType: 0,
        },
        {
          coinValue: 0.0754234,
          fiatValue: 2284.2,
          symbol: "BTC",
          curency: "USD",
          action: "Received BTC",
          actionType: 1,
        },
      ],
    },
    {
      date: "June 2, 2022",
      data: [
        {
          coinValue: 3.1754361,
          fiatValue: 5779.13,
          symbol: "ETH",
          curency: "USD",
          action: "Buy ETH",
          actionType: 0,
        },
        {
          coinValue: 0.2821331,
          fiatValue: 513.47,
          symbol: "ETH",
          curency: "USD",
          action: "Sent ETH",
          actionType: 2,
        },
        {
          coinValue: 0.1376231,
          fiatValue: 4163.84,
          symbol: "BTC",
          curency: "USD",
          action: "Received BTC",
          actionType: 1,
        },
      ],
    },
  ],
};

export const walletsData = [
  {
    icon: "/img/thumbs/bitcoin.png",
    symbol: "BTC",
    name: "Bitcoin",
    fiatValue: 15032,
    coinValue: 0.5832112,
    growshrink: 16.7,
    address: "n1RyjN4dyBDA9cKzAzAKfWoHEZkGMizFDo",
  },
  {
    icon: "/img/thumbs/ethereum.png",
    symbol: "ETH",
    name: "Ethereum",
    fiatValue: 11246,
    coinValue: 1.7294746,
    growshrink: -8.3,
    address: "0x3E6EB215F825224D33E588F29C54FF2AF21EC91D",
  },
  {
    icon: "/img/thumbs/solana.png",
    symbol: "SOL",
    name: "Solana",
    fiatValue: 8273,
    coinValue: 196.9766,
    growshrink: 4.9,
    address: "HN7cABqLq46Es1jh92dQQisAq662SmxELLLsHHe4YWrH",
  },
];
export const walletTransactions = [
  {
    id: "c456431c-8a49-430a-ab88-f7fa1cc89fa6",
    execution_time: "2023-09-04T13:09:15.555511",
    quote_symbol: "BTC",
    execution_price: 0.06307, //"column: PRICE" 0.06307 BTC
    base_symbol: "ETH",
    base_amount: 1, //"column: AMOUNT" 1 ETH
    side: "buy", //buy sell "column: ACTION"
    order_type: "limit", //"column: ORDER TYPE" LIMIT = "limit" MARKET = "market" STOP_LIMIT = "stop-limit"
    status: "FILLED", //"column: STATUS" FILLED PENDING CANCELLED FAILED PARTIALLY_FILLED
    bot_id: "0133e8f9-8d02-48bd-a2e7-728e56d56143",
    wallet_id: "f29cf1c5-34b1-4d76-8942-78a529213dbb",
  },
  {
    id: "b126721b-7c78-420d-a113-f7fa7bb78d01",
    execution_time: "2023-09-10T14:20:30.456011",
    quote_symbol: "LTC",
    execution_price: 0.12307,
    base_symbol: "XRP",
    base_amount: 2,
    side: "buy",
    order_type: "market",
    status: "PENDING",
    bot_id: "0133e8f9-8d02-48bd-a2e7-728e56d56143",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "a127831c-8c89-430a-ad88-f8gq2cd88hw9",
    execution_time: "2023-09-11T15:25:40.789056",
    quote_symbol: "BNB",
    execution_price: 0.03307,
    base_symbol: "BTC",
    base_amount: 3,
    side: "sell",
    order_type: "stop-limit",
    status: "CANCELLED",
    bot_id: "0133e8f9-8d02-48bd-a2e7-728e56d56143",
    wallet_id: "f29cf1c5-34b1-4d76-8942-78a529213dbb",
  },
  {
    id: "p237830c-9d90-411b-bd89-g9hq3df99iw0",
    execution_time: "2023-09-12T16:30:50.890076",
    quote_symbol: "DOT",
    execution_price: 0.04307,
    base_symbol: "ADA",
    base_amount: 4,
    side: "buy",
    order_type: "limit",
    status: "FILLED",
    bot_id: "0133e8f9-8d02-48bd-a2e7-728e56d56143",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "q348941d-0e01-512c-ce90-h0ir4eg00jx1",
    execution_time: "2023-09-13T17:35:50.901096",
    quote_symbol: "USDT",
    execution_price: 0.05308,
    base_symbol: "EOS",
    base_amount: 5,
    side: "sell",
    order_type: "market",
    status: "PARTIALLY_FILLED",
    bot_id: "e6f54aae-8f07-4bba-b7f5-bc15ad2e5237",
    wallet_id: "f29cf1c5-34b1-4d76-8942-78a529213dbb",
  },
  {
    id: "r459052e-1f12-623d-df01-i1js5fh11ky2",
    execution_time: "2023-09-14T18:40:50.912116",
    quote_symbol: "BCH",
    execution_price: 0.06309,
    base_symbol: "USDT",
    base_amount: 6,
    side: "buy",
    order_type: "stop-limit",
    status: "FAILED",
    bot_id: "e6f54aae-8f07-4bba-b7f5-bc15ad2e5237",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "s560163f-2g23-734e-eg12-j2kt6gi22lz3",
    execution_time: "2023-09-15T19:45:30.923136",
    quote_symbol: "ADA",
    execution_price: 0.0731,
    base_symbol: "LTC",
    base_amount: 7,
    side: "sell",
    order_type: "market",
    status: "FILLED",
    bot_id: "e6f54aae-8f07-4bba-b7f5-bc15ad2e5237",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "t671274g-3h34-845f-fh23-k3lu7hj33ma4",
    execution_time: "2023-09-16T20:50:20.934156",
    quote_symbol: "XRP",
    execution_price: 0.08311,
    base_symbol: "BNB",
    base_amount: 8,
    side: "buy",
    order_type: "limit",
    status: "CANCELLED",
    bot_id: "e6f54aae-8f07-4bba-b7f5-bc15ad2e5237",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "u782385h-4i45-956g-gi34-l4mv8ik44nb5",
    execution_time: "2023-09-17T21:55:00.945176",
    quote_symbol: "ETH",
    execution_price: 0.09312,
    base_symbol: "DOT",
    base_amount: 9,
    side: "sell",
    order_type: "stop-limit",
    status: "PENDING",
    bot_id: "m489058i-7187-0a1m-i909-1320g7243097",
    wallet_id: "f29cf1c5-34b1-4d76-8942-78a529213dbb",
  },
  {
    id: "v893496i-5j56-067h-hj45-m5nw9jl55oc6",
    execution_time: "2023-09-18T22:59:10.956196",
    quote_symbol: "LTC",
    execution_price: 0.10313,
    base_symbol: "USDT",
    base_amount: 10,
    side: "buy",
    order_type: "market",
    status: "FILLED",
    bot_id: "n599169j-7298-1b2n-j910-1421h8354198",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "w904507j-6k67-178i-ik56-n6ox0km66pd7",
    execution_time: "2023-09-19T23:59:20.967216",
    quote_symbol: "BNB",
    execution_price: 0.11314,
    base_symbol: "EOS",
    base_amount: 1,
    side: "sell",
    order_type: "limit",
    status: "PARTIALLY_FILLED",
    bot_id: "o609280k-7309-2c3o-k011-1522i9465299",
    wallet_id: "p7o926o5-kgh2-20k2-lec2-m16jn75kop6g",
  },
  {
    id: "x015618k-7l78-289j-jl67-o7py1ln77qe8",
    execution_time: "2023-09-20T01:10:30.978236",
    quote_symbol: "XLM",
    execution_price: 0.12315,
    base_symbol: "TRX",
    base_amount: 11,
    side: "buy",
    order_type: "market",
    status: "PENDING",
    bot_id: "p719391l-7410-3d4p-l012-1623j0576390",
    wallet_id: "q8p037p6-lgh3-31l3-mfd3-n17ko86lpq7h",
  },
  {
    id: "y126729l-8m89-390k-km78-p8qz2mo88rf9",
    execution_time: "2023-09-21T02:15:40.989256",
    quote_symbol: "DASH",
    execution_price: 0.13316,
    base_symbol: "XLM",
    base_amount: 12,
    side: "sell",
    order_type: "stop-limit",
    status: "CANCELLED",
    bot_id: "q829402m-7521-4e5q-m013-1724k1687491",
    wallet_id: "f29cf1c5-34b1-4d76-8942-78a529213dbb",
  },
  {
    id: "z237830m-9n90-401l-ln89-q9r3np999sg0",
    execution_time: "2023-09-22T03:20:50.000276",
    quote_symbol: "XEM",
    execution_price: 0.14317,
    base_symbol: "DASH",
    base_amount: 13,
    side: "buy",
    order_type: "limit",
    status: "FILLED",
    bot_id: "r939513n-7632-5f6r-n014-1825l2798592",
    wallet_id: "80e49cca-1724-44d0-9617-dbda294f665e",
  },
  {
    id: "a348941n-0o01-512m-mo90-r0s4op00th01",
    execution_time: "2023-09-23T04:25:50.011296",
    quote_symbol: "ZEC",
    execution_price: 0.15318,
    base_symbol: "XEM",
    base_amount: 14,
    side: "sell",
    order_type: "market",
    status: "PARTIALLY_FILLED",
    bot_id: "s049624o-7743-6g7s-o015-1926m3909693",
    wallet_id: "t1s360s9-ogn6-64o6-pgh6-q20nr19soh0a",
  },
  {
    id: "b459052o-1p12-623n-no01-s1t5pq11ui02",
    execution_time: "2023-09-24T06:30:50.022316",
    quote_symbol: "NEO",
    execution_price: 0.16319,
    base_symbol: "ZEC",
    base_amount: 15,
    side: "buy",
    order_type: "stop-limit",
    status: "FAILED",
    bot_id: "t059735p-7854-7h8t-p016-2027n5010794",
    wallet_id: "u2t471t0-pgo7-75p7-qha7-r21os21tpi1b",
  },
  {
    id: "c560163p-2q23-734o-op12-t2u6qr22vj03",
    execution_time: "2023-09-25T07:35:30.033336",
    quote_symbol: "EOS",
    execution_price: 0.1732,
    base_symbol: "NEO",
    base_amount: 16,
    side: "sell",
    order_type: "market",
    status: "FILLED",
    bot_id: "u069846q-7965-8i9u-q017-2128o6121895",
    wallet_id: "v3u582u1-qgp8-86q8-rir8-s22pt32uqj2c",
  },
  {
    id: "d671274q-3r34-845p-pq23-u3v7qr33wk04",
    execution_time: "2023-09-26T08:40:20.044356",
    quote_symbol: "BCH",
    execution_price: 0.18321,
    base_symbol: "EOS",
    base_amount: 17,
    side: "buy",
    order_type: "limit",
    status: "CANCELLED",
    bot_id: "v079957r-8076-9j0v-r018-2229p7232996",
    wallet_id: "w4v693v2-rgp9-97r9-sjr9-t23qu43vrk3d",
  },
  {
    id: "e782385r-4s45-956q-qr34-v4w8qs44xl05",
    execution_time: "2023-09-27T09:45:00.055376",
    quote_symbol: "LTC",
    execution_price: 0.19322,
    base_symbol: "BCH",
    base_amount: 18,
    side: "sell",
    order_type: "market",
    status: "PARTIALLY_FILLED",
    bot_id: "w080068s-8187-0k1w-s019-2330q8344097",
    wallet_id: "x5w704w3-shp0-08s0-tks0-u24rv54ws50e",
  },
  {
    id: "f893496s-5t56-067r-rs45-w5x9rt55ym06",
    execution_time: "2023-09-28T10:50:10.066396",
    quote_symbol: "ADA",
    execution_price: 0.20323,
    base_symbol: "LTC",
    base_amount: 19,
    side: "buy",
    order_type: "stop-limit",
    status: "FILLED",
    bot_id: "x090179t-8298-1l2x-t020-2431r9455198",
    wallet_id: "y6x815x4-thq1-19t1-ult1-v25sw65xt60f",
  },
  {
    id: "g904507t-6u67-178u-ut56-x6y0kt66zn07",
    execution_time: "2023-09-29T11:55:20.077416",
    quote_symbol: "XRP",
    execution_price: 0.21324,
    base_symbol: "ADA",
    base_amount: 20,
    side: "sell",
    order_type: "limit",
    status: "CANCELLED",
    bot_id: "y100280u-8309-2c3y-u011-2532s0566299",
    wallet_id: "z7y926y5-yh2z-20z2-lez2-w26tx76yz70g",
  },
  {
    id: "i012618u-8v78-289v-vv67-v7z1vu77ae08",
    execution_time: "2023-09-30T12:00:30.088436",
    quote_symbol: "DOT",
    execution_price: 0.22325,
    base_symbol: "XRP",
    base_amount: 21,
    side: "buy",
    order_type: "market",
    status: "PENDING",
    bot_id: "z110391v-8410-3d4v-v012-2633u0676390",
    wallet_id: "a8z037z6-zg3z-31z3-mfd3-x27uv87zq81h",
  },
  {
    id: "j123729v-9w89-390w-wv78-w8a2wa88bf09",
    execution_time: "2023-09-01T13:05:40.099456",
    quote_symbol: "XTZ",
    execution_price: 0.23326,
    base_symbol: "DOT",
    base_amount: 22,
    side: "sell",
    order_type: "stop-limit",
    status: "CANCELLED",
    bot_id: "a120402w-8521-4e5w-w013-2734v1787491",
    wallet_id: "b9a148a7-ag4a-42a4-nge4-y28vw98aq82i",
  },
  {
    id: "k234830w-0x90-401x-xx89-x9b3xb99cg10",
    execution_time: "2023-10-01T14:10:50.010476",
    quote_symbol: "LINK",
    execution_price: 0.24327,
    base_symbol: "XTZ",
    base_amount: 23,
    side: "buy",
    order_type: "limit",
    status: "FILLED",
    bot_id: "b130513x-8632-5f6x-x014-2835w2898592",
    wallet_id: "c0b259b8-bgb5-53b5-ofg5-z29xw09xb93j",
  },
  {
    id: "l345941x-1y01-512y-yy90-y0c4yc00dh11",
    execution_time: "2023-10-02T15:15:50.021496",
    quote_symbol: "UNI",
    execution_price: 0.25328,
    base_symbol: "LINK",
    base_amount: 24,
    side: "sell",
    order_type: "market",
    status: "PARTIALLY_FILLED",
    bot_id: "c140624y-8743-6g7y-y015-2936x3909693",
    wallet_id: "d1c360c9-cgc6-64c6-pgh6-a30yx41yci2b",
  },
];

export const transactionHistoryData = [
  {
    trade: [
      {
        actionType: 0,
        status: 1,
        action: "Buy BTC",
        date: 1660132800,
        symbol: "BTC",
        price: 35843.12,
        amount: 1.214321,
      },
      {
        actionType: 0,
        status: 1,
        action: "Buy BTC",
        date: 1659132800,
        symbol: "BTC",
        price: 7324.94,
        amount: 0.256578,
      },
      {
        actionType: 1,
        status: 0,
        action: "Sell ETH",
        date: 1658132800,
        symbol: "ETH",
        price: 16834.42,
        amount: 9.346292,
      },
      {
        actionType: 2,
        status: 2,
        action: "Swap ETH to BTC",
        date: 1658132800,
        symbol: "ETH",
        price: 4000.0,
        amount: 2.220581,
      },
      {
        actionType: 0,
        status: 0,
        action: "Buy SOL",
        date: 1656232800,
        symbol: "SOL",
        price: 6372.57,
        amount: 163.3846,
      },
      {
        actionType: 0,
        status: 0,
        action: "Buy BTC",
        date: 1655532800,
        symbol: "BTC",
        price: 5288.36,
        amount: 0.185462,
      },
      {
        actionType: 0,
        status: 0,
        action: "Buy ETH",
        date: 1654932800,
        symbol: "ETH",
        price: 1376.76,
        amount: 0.762942,
      },
      {
        actionType: 1,
        status: 0,
        action: "Sell BTC",
        date: 1654132800,
        symbol: "BTC",
        price: 9345.74,
        amount: 0.315731,
      },
      {
        actionType: 2,
        status: 2,
        action: "Swap BTC to ETH",
        date: 1650132800,
        symbol: "BTC",
        price: 6540.21,
        amount: 0.227392,
      },
      {
        actionType: 0,
        status: 2,
        action: "Buy ETH",
        date: 1649832800,
        symbol: "ETH",
        price: 3832.55,
        amount: 2.131846,
      },
      {
        actionType: 2,
        status: 2,
        action: "Swap BTC to ETH",
        date: 1650132800,
        symbol: "BTC",
        price: 6540.21,
        amount: 0.227392,
      },
      {
        actionType: 0,
        status: 2,
        action: "Buy ETH",
        date: 1649832800,
        symbol: "ETH",
        price: 3832.55,
        amount: 2.131846,
      },
    ],
    deposit: [
      {
        id: "1r4t4LPT89",
        date: 1660132800,
        amount: 5221.42,
        status: 1,
      },
      {
        id: "x4M7vUk0Ph",
        date: 1659132800,
        amount: 2511.79,
        status: 0,
      },
      {
        id: "NMEddCfzOU",
        date: 1658132800,
        amount: 1621.69,
        status: 0,
      },
      {
        id: "73FCyE5A01",
        date: 1657332800,
        amount: 8204.37,
        status: 2,
      },
      {
        id: "BXfw8E4jqh",
        date: 1656232800,
        amount: 9323.16,
        status: 0,
      },
      {
        id: "VVC9sqyGW0",
        date: 1655532800,
        amount: 3247.87,
        status: 0,
      },
      {
        id: "knl7mhjFVJ",
        date: 1654932800,
        amount: 1935.31,
        status: 0,
      },
    ],
    withdraw: [
      {
        id: "BxwE2fNELZ",
        date: 1660132800,
        amount: 4522.75,
        status: 1,
      },
      {
        id: "G1xtpaE76e",
        date: 1659132800,
        amount: 5761.13,
        status: 0,
      },
      {
        id: "hpqMH7sUc4",
        date: 1658132800,
        amount: 9762.43,
        status: 0,
      },
      {
        id: "WAYL1VDzQ2",
        date: 1657332800,
        amount: 1123.56,
        status: 2,
      },
      {
        id: "KEzIP7m6Dn",
        date: 1656232800,
        amount: 3276.14,
        status: 0,
      },
      {
        id: "UumwPM9ZOY",
        date: 1655532800,
        amount: 5422.05,
        status: 0,
      },
      {
        id: "INfkGxbeFR",
        date: 1654932800,
        amount: 1258.39,
        status: 1,
      },
      {
        id: "0p1ozYRslq",
        date: 1654132800,
        amount: 9858.17,
        status: 0,
      },
    ],
  },
];
export const marketDataKeys = { list: ["all", "spot", "futures"] };
export const marketData = {
  all: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      price: 29877.3,
      change: -5.06,
      volumn: 35664.61,
      marketCap: 570114.24,
      img: "/img/thumbs/bitcoin.png",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      price: 1785.91,
      change: -5.72,
      volumn: 20475.96,
      marketCap: 215744.97,
      img: "/img/thumbs/ethereum.png",
    },
    {
      name: "TetherUS",
      symbol: "USDT",
      price: 1,
      change: 0.11,
      volumn: 68677.15,
      marketCap: 72420.54,
      img: "/img/thumbs/tether-us.png",
    },
    {
      name: "Solana",
      symbol: "SOL",
      price: 40.25,
      change: 1.9,
      volumn: 1217.08,
      marketCap: 13752.04,
      img: "/img/thumbs/solana.png",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      price: 0.07979,
      change: -0.31,
      volumn: 269.01,
      marketCap: 10575.18,
      img: "/img/thumbs/doge.png",
    },
    {
      name: "Polkadot",
      symbol: "DOT",
      price: 9.18,
      change: 0.99,
      volumn: 339.99,
      marketCap: 9056.1,
      img: "/img/thumbs/polkadot.png",
    },
    {
      name: "TRON",
      symbol: "TRX",
      price: 0.08108,
      change: 0.31,
      volumn: 693.31,
      marketCap: 7533.89,
      img: "/img/thumbs/tron.png",
    },
    {
      name: "Avalanche",
      symbol: "AVAX",
      price: 24.65,
      change: 0.04,
      volumn: 640.76,
      marketCap: 6919.55,
      img: "/img/thumbs/avalanche.png",
    },
    {
      name: "SHIBA INU",
      symbol: "SHIB",
      price: 0.00001,
      change: -1.49,
      volumn: 369.34,
      marketCap: 6039.69,
      img: "/img/thumbs/shiba-inu.png",
    },
    {
      name: "Polygon",
      symbol: "MATIC",
      price: 0.633,
      change: 2.93,
      volumn: 543.23,
      marketCap: 5036.26,
      img: "/img/thumbs/polygon.png",
    },
    {
      name: "Stellar Lumens",
      symbol: "XLM",
      price: 0.1404,
      change: -1.89,
      volumn: 121.73,
      marketCap: 3501.92,
      img: "/img/thumbs/stellar-lumens.png",
    },
    {
      name: "EOS",
      symbol: "EOS",
      price: 1.25,
      change: -1.57,
      volumn: 234.66,
      marketCap: 1239.57,
      img: "/img/thumbs/eos.png",
    },
  ],
  spot: [
    {
      name: "BTC/USDT",
      symbol: "BTC",
      amount: 29877.3,
      price: 29877.3,
      change: 0.07,
      turnOver: 495.98,
      volumn: 152079.48,
      high: 30680.09,
      low: 29877.3,
    },
    {
      name: "ETH/USDT",
      symbol: "ETH",
      amount: 1728.13,
      price: 1728.13,
      change: 0.06,
      turnOver: 152.5,
      volumn: 147429.2,
      high: 1730.71,
      low: 1724.22,
    },
    {
      name: "DOT/USDT",
      symbol: "DOT",
      amount: 8.7175,
      price: 8.7,
      change: -1.47,
      turnOver: 467,
      volumn: 554369,
      high: 8.8553,
      low: 8.582,
    },
    {
      symbol: "XRP",
      name: "XRP/USDT",
      amount: 0.3811,
      price: 0.38,
      change: -1.63,
      turnOver: 19.4,
      volumn: 48977539,
      high: 0.3877,
      low: 0.3768,
    },
    {
      name: "LINK/USDT",
      symbol: "LINK",
      amount: 8.3476,
      price: 8.35,
      change: -2.84,
      turnOver: 10.27,
      volumn: 11438939,
      high: 8.627,
      low: 8.231,
    },
    {
      name: "BCH/USDT",
      symbol: "BCH",
      amount: 166.36,
      price: 166.36,
      change: -2.44,
      turnOver: 3.02,
      volumn: 1756432,
      high: 172.08,
      low: 165.86,
    },
    {
      name: "LTC/USDT",
      symbol: "LTC",
      amount: 56.48,
      price: 56.48,
      change: -1.67,
      turnOver: 6.87,
      volumn: 117724,
      high: 57.5,
      low: 55.56,
    },
    {
      name: "BSV/USDT",
      symbol: "BSV",
      amount: 57.8047,
      price: 57.8,
      change: -0.78,
      turnOver: 1.73,
      volumn: 30348,
      high: 58.79,
      low: 57.01,
    },
    {
      name: "ADA/USDT",
      symbol: "ADA",
      amount: 0.5754,
      price: 0.57,
      change: -2.07,
      turnOver: 9.27,
      volumn: 15306578,
      high: 0.5885,
      low: 0.6953,
    },
    {
      name: "EOS/USDT",
      symbol: "EOS",
      amount: 1.1842,
      price: 1.18,
      change: 1.96,
      turnOver: 3.09,
      volumn: 2587323,
      high: 1.2099,
      low: 1.1708,
    },
    {
      name: "TRX/USDT",
      symbol: "TRX",
      amount: 0.079,
      price: 0.079,
      change: -1.14,
      turnOver: 18.37,
      volumn: 228015768,
      high: 0.08,
      low: 0.079,
    },
    {
      name: "XMR/USDT",
      symbol: "XMR",
      amount: 173.67,
      price: 173.67,
      change: 1.12,
      turnOver: 5.04,
      volumn: 900314,
      high: 175.18,
      low: 170.39,
    },
    {
      name: "IOTA/USDT",
      symbol: "IOTA",
      amount: 0.3228,
      price: 0.32,
      change: -1.43,
      turnOver: 0.18,
      volumn: 327851,
      high: 0.3287,
      low: 0.3184,
    },
  ],
  futures: [
    {
      name: "BTCUSDT",
      symbol: "BTC",
      amount: 29877.3,
      price: 29877.3,
      change: 0.07,
      turnOver: 495.98,
      volumn: 152079.48,
      high: 30680.09,
      low: 29877.3,
    },
    {
      name: "ETHUSDT",
      symbol: "ETH",
      amount: 1728.13,
      price: 1728.13,
      change: 0.06,
      turnOver: 152.5,
      volumn: 147429.2,
      high: 1730.71,
      low: 1724.22,
    },
    {
      name: "DOTUSDT",
      symbol: "DOT",
      amount: 8.7175,
      price: 8.7,
      change: -1.47,
      turnOver: 467,
      volumn: 554369,
      high: 8.8553,
      low: 8.582,
    },
    {
      symbol: "XRP",
      name: "XRPUSDT",
      amount: 0.3811,
      price: 0.38,
      change: -1.63,
      turnOver: 19.4,
      volumn: 48977539,
      high: 0.3877,
      low: 0.3768,
    },
    {
      name: "LINKUSDT",
      symbol: "LINK",
      amount: 8.3476,
      price: 8.35,
      change: -2.84,
      turnOver: 10.27,
      volumn: 11438939,
      high: 8.627,
      low: 8.231,
    },
    {
      name: "BCHUSDT",
      symbol: "BCH",
      amount: 166.36,
      price: 166.36,
      change: -2.44,
      turnOver: 3.02,
      volumn: 1756432,
      high: 172.08,
      low: 165.86,
    },
    {
      name: "LTCUSDT",
      symbol: "LTC",
      amount: 56.48,
      price: 56.48,
      change: -1.67,
      turnOver: 6.87,
      volumn: 117724,
      high: 57.5,
      low: 55.56,
    },
    {
      name: "BSVUSDT",
      symbol: "BSV",
      amount: 57.8047,
      price: 57.8,
      change: -0.78,
      turnOver: 1.73,
      volumn: 30348,
      high: 58.79,
      low: 57.01,
    },
    {
      name: "ADAUSDT",
      symbol: "ADA",
      amount: 0.5754,
      price: 0.57,
      change: -2.07,
      turnOver: 9.27,
      volumn: 15306578,
      high: 0.5885,
      low: 0.6953,
    },
    {
      name: "EOSUSDT",
      symbol: "EOS",
      amount: 1.1842,
      price: 1.18,
      change: 1.96,
      turnOver: 3.09,
      volumn: 2587323,
      high: 1.2099,
      low: 1.1708,
    },
    {
      name: "TRXUSDT",
      symbol: "TRX",
      amount: 0.079,
      price: 0.079,
      change: -1.14,
      turnOver: 18.37,
      volumn: 228015768,
      high: 0.08,
      low: 0.079,
    },
    {
      name: "XMRUSDT",
      symbol: "XMR",
      amount: 173.67,
      price: 173.67,
      change: 1.12,
      turnOver: 5.04,
      volumn: 900314,
      high: 175.18,
      low: 170.39,
    },
    {
      name: "IOTAUSDT",
      symbol: "IOTA",
      amount: 0.3228,
      price: 0.32,
      change: -1.43,
      turnOver: 0.18,
      volumn: 327851,
      high: 0.3287,
      low: 0.3184,
    },
  ],
};

export const cryptoDashboardData = {
  portfolioStatsData: {
    week: {
      series: [
        {
          name: "Porfolio Balance",
          data: [
            24773.31, 33188.05, 29175.61, 36728.42, 34730.97, 43912.42,
            40774.19,
          ],
        },
      ],
      timeRange: [
        "01 Feb",
        "02 Feb",
        "03 Feb",
        "04 Feb",
        "05 Feb",
        "06 Feb",
        "07 Feb",
      ],
    },
    month: {
      series: [
        {
          name: "Porfolio Balance",
          data: [
            14576.39, 23895.12, 19473.64, 26454.96, 24741.98, 33153.32,
            30218.32, 37645.11, 35556.15, 38886.34, 36135.95, 45966.12,
          ],
        },
      ],
      timeRange: [
        "02 Jan",
        "05 Jan",
        "07 Jan",
        "10 Jan",
        "13 Jan",
        "15 Jan",
        "18 Jan",
        "20 Jan",
        "23 Jan",
        "25 Jan",
        "28 Jan",
        "30 Jan",
      ],
    },
    year: {
      series: [
        {
          name: "Porfolio Balance",
          data: [
            20874.12, 26583.56, 23943.65, 24453.84, 22473.43, 29185.37,
            27815.48, 36834.69, 32422.11, 35888.32, 32112.84, 38764.22,
          ],
        },
      ],
      timeRange: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  },
  recentAcivityData: [
    {
      date: "June 3, 2022",
      data: [
        {
          coinValue: 0.0383522,
          fiatValue: 1161.5,
          symbol: "BTC",
          curency: "USD",
          action: "Buy BTC",
          actionType: 0,
        },
        {
          coinValue: 0.0754234,
          fiatValue: 2284.2,
          symbol: "BTC",
          curency: "USD",
          action: "Received BTC",
          actionType: 1,
        },
      ],
    },
    {
      date: "June 2, 2022",
      data: [
        {
          coinValue: 3.1754361,
          fiatValue: 5779.13,
          symbol: "ETH",
          curency: "USD",
          action: "Buy ETH",
          actionType: 0,
        },
        {
          coinValue: 0.2821331,
          fiatValue: 513.47,
          symbol: "ETH",
          curency: "USD",
          action: "Sent ETH",
          actionType: 2,
        },
        {
          coinValue: 0.1376231,
          fiatValue: 4163.84,
          symbol: "BTC",
          curency: "USD",
          action: "Received BTC",
          actionType: 1,
        },
      ],
    },
  ],
  marketValueData: marketData.all.filter((_, index) => index <= 4),
  holdingsData: [
    ...walletsData,
    {
      icon: "/img/thumbs/litecoin.png",
      symbol: "LTC",
      name: "Litecoin",
      fiatValue: 5291,
      coinValue: 93.67,
      growshrink: 11.34,
    },
  ],
};
