import axios from "axios";
import appConfig from "configs/app.config";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import {
  REFRESH_TOKEN_STORE_NAME,
  TOKEN_STORE_NAME,
} from "constants/app.constant";
import jwt_decode from "jwt-decode";
import { decodeTokenAndStoreDetails } from "utils/utils";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
import {
  apiRefreshToken,
  OPEN_ENDPOINTS,
  REFRESH_TOKEN_ENDPOINT,
} from "./AuthService";

/*
 * BaseService configuration for API calls.
 *
 * This service handles token expiration and refresh logic:
 * - Short-lived access tokens are used for security.
 * - If the access token is expired, a single token refresh operation is initiated.
 * - Subsequent API calls wait for the token refresh to complete.
 * - The refreshed token is then used for the pending API calls.
 * - If the refresh token API fails, the user is signed out.
 */

const unauthorizedCodes = [401];
let refreshingToken = false;
let refreshingTokenPromise = null;

const BaseService = axios.create({
  baseURL: appConfig.apiPrefix,
  timeout: 60000,
});

const isTokenExpired = (token) => {
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000; // Convert to seconds
  return decoded.exp < currentTime;
};

BaseService.interceptors.request.use(
  async (config) => {
    // Check if the request is to an open endpoint
    if (OPEN_ENDPOINTS.includes(config.url)) return config;

    // Handle the refresh token endpoint separately
    if (config.url.includes(REFRESH_TOKEN_ENDPOINT)) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORE_NAME);
      if (!!refreshToken) {
        config.headers[
          REQUEST_HEADER_AUTH_KEY
        ] = `${TOKEN_TYPE}${refreshToken}`;
      }
    } else {
      if (refreshingToken) {
        // Wait if a token refresh is already in progress
        await refreshingTokenPromise;
      } else {
        let accessToken = localStorage.getItem(TOKEN_STORE_NAME);
        if (accessToken && isTokenExpired(accessToken)) {
          refreshingToken = true;
          // Refresh the token and update the promise
          refreshingTokenPromise = apiRefreshToken()
            .then((response) => {
              if (response.data?.access_token) {
                decodeTokenAndStoreDetails(response.data);
                accessToken = response.data.access_token;
              }
              refreshingToken = false;
              refreshingTokenPromise = null;
              return accessToken;
            })
            .catch((error) => {
              refreshingToken = false;
              refreshingTokenPromise = null;
              throw error;
            });

          // Wait for the token refresh to complete
          await refreshingTokenPromise;
        }
      }

      // Set the new access token in the request header
      let accessToken = localStorage.getItem(TOKEN_STORE_NAME);
      if (!!accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response && unauthorizedCodes.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
    }

    return Promise.reject(error);
  }
);

export default BaseService;
