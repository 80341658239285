export const myBots = [
  {
    id: "e6f54aae-8f07-4bba-b7f5-bc15ad2e5237",
    name: "bot1",
    cards: [
      {
        id: "045462c6-d63e-4538-b791-e084140c7dd21677101038944",
        template: {
          id: "045462c6-d63e-4538-b791-e084140c7dd2",
          title: "Price",
          description: "get the price of a ticker",
          form: [
            {
              id: "a0182eb7-ec9a-4e1c-b510-80b8b4c2e61a",
              key: "ticker",
              options: [],
              type: "STRING",
            },
            {
              id: "a09bcf2a-356e-4579-bd14-05e6fc8aa104",
              key: "exchange",
              options: [],
              type: "STRING",
            },
          ],
          fetcherName: "price",
          templateType: "INPUT",
          templateCategory: "PRICES",
          outputType: "FLOAT",
          frequancy: 30,
        },
        fieldValues: [
          {
            id: "1fa089a1-35a3-4f6f-bfc4-fe19ef3816df",
            key: "exchange",
            value: "Binance",
            fromCard: false,
          },
          {
            id: "10a205a9-90f1-4c41-bc00-a76a2473b186",
            key: "ticker",
            value: "BTCUSDT",
            fromCard: false,
          },
        ],
        positionTop: 80.0,
        positionLeft: 1062.0,
        is_on: false,
      },
      {
        id: "2f1fee06-f969-489c-a0bc-95f8c1d035251677101053573",
        template: {
          id: "2f1fee06-f969-489c-a0bc-95f8c1d03525",
          title: "superior",
          description: "if a > b  returns true",
          form: [
            {
              id: "6a5e5eb0-b12a-4fe9-9401-9e719e31da46",
              key: "a",
              options: [],
              type: "FLOAT",
            },
            {
              id: "23be691b-1568-4cc9-a497-65396ced5cc1",
              key: "b",
              options: [],
              type: "FLOAT",
            },
          ],
          fetcherName: "superior",
          templateType: "OPERATION",
          templateCategory: "BASIC_LOGICAL_OPERATIONS",
          outputType: "BOOLEAN",
          frequancy: null,
        },
        fieldValues: [
          {
            id: "05a65591-eec8-452b-8242-35f5675964b8",
            key: "a",
            value: "045462c6-d63e-4538-b791-e084140c7dd21677101038944",
            fromCard: true,
          },
          {
            id: "dff982f7-227c-42f4-9ffc-10f51213cbc0",
            key: "b",
            value: "25000",
            fromCard: false,
          },
        ],
        positionTop: 185.0,
        positionLeft: 738.0,
        is_on: false,
      },
      {
        id: "e51008af-73ea-4220-8ea7-8600506164831677101077459",
        template: {
          id: "e51008af-73ea-4220-8ea7-860050616483",
          title: "notify",
          description: "send email if condition",
          form: [
            {
              id: "297076b5-741d-4735-8923-375f79d44148",
              key: "to",
              options: [],
              type: "STRING",
            },
            {
              id: "fb99e047-0994-4fa8-b4b3-d614a0b2aacf",
              key: "condition",
              options: [],
              type: "BOOLEAN",
            },
            {
              id: "3e4658de-0b5e-4497-a6f4-99a30d579be0",
              key: "subject",
              options: [],
              type: "STRING",
            },
            {
              id: "fc2a76e5-7b67-490b-bba9-7bcbf1fd5953",
              key: "message",
              options: [],
              type: "STRING",
            },
          ],
          fetcherName: "notify",
          templateType: "ACTION",
          templateCategory: "BASIC_ACTION",
          outputType: "BOOLEAN",
          frequancy: null,
        },
        fieldValues: [
          {
            id: "c0af79b2-f942-47fd-82aa-7d1ab402b1b0",
            key: "to",
            value: "a.naji9710@gmail.com",
            fromCard: false,
          },
          {
            id: "cf8dd699-3598-4b8b-843c-977dce95f530",
            key: "condition",
            value: "2f1fee06-f969-489c-a0bc-95f8c1d035251677101053573",
            fromCard: true,
          },
          {
            id: "331d0b51-812d-4142-9214-7c9760309034",
            key: "subject",
            value: "Notif",
            fromCard: false,
          },
          {
            id: "148c4eae-f035-46a1-bfcf-7bbe9ea8a7d7",
            key: "message",
            value: "Buy",
            fromCard: false,
          },
        ],
        positionTop: 119.0,
        positionLeft: 272.0,
        is_on: false,
      },
    ],
    state: "RUNNING",
  },
  {
    id: "0133e8f9-8d02-48bd-a2e7-728e56d56143",
    name: "Bot 2",
    cards: [
      {
        id: "045462c6-d63e-4538-b791-e084140c7dd21677104167113",
        template: {
          id: "045462c6-d63e-4538-b791-e084140c7dd2",
          title: "Price",
          description: "get the price of a ticker",
          form: [
            {
              id: "a0182eb7-ec9a-4e1c-b510-80b8b4c2e61a",
              key: "ticker",
              options: [],
              type: "STRING",
            },
            {
              id: "a09bcf2a-356e-4579-bd14-05e6fc8aa104",
              key: "exchange",
              options: [],
              type: "STRING",
            },
          ],
          fetcherName: "price",
          templateType: "INPUT",
          templateCategory: "PRICES",
          outputType: "FLOAT",
          frequancy: 30,
        },
        fieldValues: [
          {
            id: "be1229ba-234c-4d8f-af4a-689e51804a98",
            key: "ticker",
            value: "BTCUSDT",
            fromCard: false,
          },
          {
            id: "b2ab4754-e503-4bd7-a364-96c88979c305",
            key: "exchange",
            value: "Binance",
            fromCard: false,
          },
        ],
        positionTop: 94.0,
        positionLeft: 965.0,
        is_on: false,
      },
      {
        id: "2f1fee06-f969-489c-a0bc-95f8c1d035251677104178811",
        template: {
          id: "2f1fee06-f969-489c-a0bc-95f8c1d03525",
          title: "superior",
          description: "if a > b  returns true",
          form: [
            {
              id: "6a5e5eb0-b12a-4fe9-9401-9e719e31da46",
              key: "a",
              options: [],
              type: "FLOAT",
            },
            {
              id: "23be691b-1568-4cc9-a497-65396ced5cc1",
              key: "b",
              options: [],
              type: "FLOAT",
            },
          ],
          fetcherName: "superior",
          templateType: "OPERATION",
          templateCategory: "BASIC_LOGICAL_OPERATIONS",
          outputType: "BOOLEAN",
          frequancy: null,
        },
        fieldValues: [
          {
            id: "56ed5163-9c02-470f-b94e-64631898c28d",
            key: "a",
            value: "045462c6-d63e-4538-b791-e084140c7dd21677104167113",
            fromCard: true,
          },
          {
            id: "15c7e06d-646d-475a-92ee-ffd7224d11cf",
            key: "b",
            value: "30000",
            fromCard: false,
          },
        ],
        positionTop: 53.0,
        positionLeft: 495.0,
        is_on: false,
      },
      {
        id: "e51008af-73ea-4220-8ea7-8600506164831677104189378",
        template: {
          id: "e51008af-73ea-4220-8ea7-860050616483",
          title: "notify",
          description: "send email if condition",
          form: [
            {
              id: "297076b5-741d-4735-8923-375f79d44148",
              key: "to",
              options: [],
              type: "STRING",
            },
            {
              id: "fb99e047-0994-4fa8-b4b3-d614a0b2aacf",
              key: "condition",
              options: [],
              type: "BOOLEAN",
            },
            {
              id: "3e4658de-0b5e-4497-a6f4-99a30d579be0",
              key: "subject",
              options: [],
              type: "STRING",
            },
            {
              id: "fc2a76e5-7b67-490b-bba9-7bcbf1fd5953",
              key: "message",
              options: [],
              type: "STRING",
            },
          ],
          fetcherName: "notify",
          templateType: "ACTION",
          templateCategory: "BASIC_ACTION",
          outputType: "BOOLEAN",
          frequancy: null,
        },
        fieldValues: [
          {
            id: "cb0af563-3305-436f-8a77-98a3ab51040a",
            key: "to",
            value: "anas",
            fromCard: false,
          },
          {
            id: "f0ae0d45-2f99-48da-9705-729503170213",
            key: "condition",
            value: "2f1fee06-f969-489c-a0bc-95f8c1d035251677104178811",
            fromCard: true,
          },
          {
            id: "58a48ac1-8050-4de1-b6a1-a872a61d6b1c",
            key: "subject",
            value: "Sma3",
            fromCard: false,
          },
          {
            id: "b20b3fd7-4ac3-43d6-970b-925244ba08f8",
            key: "message",
            value: "045462c6-d63e-4538-b791-e084140c7dd21677104167113",
            fromCard: true,
          },
        ],
        positionTop: 119.0,
        positionLeft: 206.0,
        is_on: false,
      },
    ],
    state: "DRAFT",
  },
];
export const logs = [
  {
    id: "69e53d0d-61e3-41eb-b371-59745269ac3e",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:37:29.932307",
    log: " || action Concat: 26156.4anass || ",
  },
  {
    id: "ba7118ce-5070-4fa3-be13-32d852d90198",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:36:31.618659",
    log: " || action Concat: 26156.4anass || ",
  },
  {
    id: "d8406a62-4a2f-4b00-8335-5d83a6beff55",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:35:29.805904",
    log: " || action Concat: 26152.3anass || ",
  },
  {
    id: "24894983-737f-49ff-a4ad-b526d5dd547a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:34:29.533974",
    log: " || action Concat: 26152.73anass || ",
  },
  {
    id: "19854beb-a295-4e37-bfd4-c129f40d3d23",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:33:29.485059",
    log: " || action Concat: 26154.07anass || ",
  },
  {
    id: "04355195-83c9-4429-9ed3-650a1f3479e8",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:32:29.521885",
    log: " || action Concat: 26160.45anass || ",
  },
  {
    id: "a70045aa-0ce1-41a3-b9c7-1c3e9812c873",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:31:36.582854",
    log: " || action Concat: 26160.2anass || ",
  },
  {
    id: "cc81f8cc-0691-4ce8-8a56-1d39b5258dd6",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:30:29.226070",
    log: " || action Concat: 26162.18anass || ",
  },
  {
    id: "3b80b9fb-e41e-4eff-af3f-5b78aea8f1cd",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:29:29.206831",
    log: " || action Concat: 26158.96anass || ",
  },
  {
    id: "70d31c5e-cf82-4157-8f0b-eed64f144a38",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:28:30.497031",
    log: " || action Concat: 26154.1anass || ",
  },
  {
    id: "1974badb-b8c1-4fdd-9b03-b493c0d979d9",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:27:31.696657",
    log: " || action Concat: 26147.07anass || ",
  },
  {
    id: "11e61b63-c065-4cab-833e-ed80993a743d",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:26:28.755759",
    log: " || action Concat: 26150.0anass || ",
  },
  {
    id: "2e452e8a-e683-4377-9049-ee27ea88db1a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:25:28.686185",
    log: " || action Concat: 26153.18anass || ",
  },
  {
    id: "456143e6-c7b1-4cc6-ade5-5cce319d8f0c",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:24:28.656774",
    log: " || action Concat: 26153.19anass || ",
  },
  {
    id: "1dcf011f-89fa-4de6-b534-df255e0a9e03",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:23:29.145801",
    log: " || action Concat: 26153.18anass || ",
  },
  {
    id: "7757145f-74e6-424d-93e7-c5a3d383f256",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:22:28.421012",
    log: " || action Concat: 26153.19anass || ",
  },
  {
    id: "92bf8e2f-9b6e-4278-967d-864c8ae4a609",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:21:29.603671",
    log: " || action Concat: 26157.01anass || ",
  },
  {
    id: "2e2da55f-197a-47cb-a5f3-a5c450ab07f2",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:20:28.340417",
    log: " || action Concat: 26160.0anass || ",
  },
  {
    id: "60d1743d-9ba8-4eab-aee8-ae421e4f0b57",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:19:29.988294",
    log: " || action Concat: 26160.0anass || ",
  },
  {
    id: "41b78d40-8813-48a2-bf48-f4d34a51694d",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:18:29.307910",
    log: " || action Concat: 26154.72anass || ",
  },
  {
    id: "de640bba-7d70-4f43-b728-6152881d3003",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:17:28.693890",
    log: " || action Concat: 26156.46anass || ",
  },
  {
    id: "8368f41a-8f13-4716-8066-86892713469b",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:16:32.687241",
    log: " || action Concat: 26152.96anass || ",
  },
  {
    id: "cbf8df7a-2da5-4515-9d10-01d74e2026c5",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:15:11.420326",
    log: " || action Concat: 26136.36anass || ",
  },
  {
    id: "c2217f67-fa4e-4015-8c63-eae0ad17bc1c",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:14:11.422217",
    log: " || action Concat: 26136.41anass || ",
  },
  {
    id: "d5295e17-73c9-4fdd-a235-d2332c8071f2",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:13:11.481185",
    log: " || action Concat: 26138.79anass || ",
  },
  {
    id: "9d38eb35-e438-402c-8d8e-e20cc5894149",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:10:41.282930",
    log: " || action Concat: 26153.1anass || ",
  },
  {
    id: "96459f1d-40d5-451a-acc7-461d18fd5dc0",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:09:42.383267",
    log: " || action Concat: 26154.84anass || ",
  },
  {
    id: "429601c4-b510-4ce9-a9c1-fec316cbe613",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:08:41.475645",
    log: " || action Concat: 26160.21anass || ",
  },
  {
    id: "22941720-0d15-4825-999a-3c944d90df45",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:07:41.002590",
    log: " || action Concat: 26161.93anass || ",
  },
  {
    id: "60fb8770-8a46-4148-8928-e35fb8caaf42",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:06:22.587123",
    log: " || action Concat: 26158.53anass || ",
  },
  {
    id: "903ea194-a447-46d3-8b9a-04a42f22031e",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:05:21.955794",
    log: " || action Concat: 26160.49anass || ",
  },
  {
    id: "575a20cf-98ca-4268-8006-2721aac2fd59",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:04:21.897040",
    log: " || action Concat: 26158.53anass || ",
  },
  {
    id: "3df03fbd-a813-4339-b8eb-8ffc32310342",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:03:22.412049",
    log: " || action Concat: 26160.01anass || ",
  },
  {
    id: "a37cca28-c2bd-4813-a017-908dbdfcab56",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:02:21.795485",
    log: " || action Concat: 26163.82anass || ",
  },
  {
    id: "b5f92c26-3de7-46ef-a2a7-c77ee133f4b1",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T15:01:22.662163",
    log: " || action Concat: 26154.33anass || ",
  },
  {
    id: "96181de1-1781-4570-8d0f-47be70d96ad4",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:59:57.237258",
    log: " || action Concat: 26145.27anass || ",
  },
  {
    id: "01973bd2-fa6f-4fea-804f-c630a1a77482",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:58:57.308134",
    log: " || action Concat: 26147.72anass || ",
  },
  {
    id: "8bb05119-5de3-4aa2-960e-85ccd597583f",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:57:58.173000",
    log: " || action Concat: 26155.0anass || ",
  },
  {
    id: "c89ae39c-eeba-474e-af72-997fdd75a88c",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:56:57.216886",
    log: " || action Concat: 26139.39anass || ",
  },
  {
    id: "88ab60dd-1724-4f4d-b36c-6993b8dba2d7",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:55:57.691107",
    log: " || action Concat: 26139.38anass || ",
  },
  {
    id: "ec820179-92bd-4dc4-b7bf-9db7fc2c8d94",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:54:56.927166",
    log: " || action Concat: 26135.02anass || ",
  },
  {
    id: "ce8623bd-c9bd-41ac-a7e0-e1124f2f47ef",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:53:57.389325",
    log: " || action Concat: 26142.16anass || ",
  },
  {
    id: "c0a951a6-5109-44f8-b96e-a2efa7c3c5f4",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:52:58.605494",
    log: " || action Concat: 26140.0anass || ",
  },
  {
    id: "46be3efa-42b4-4d35-9f1a-e6c5b84d1bf2",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:51:56.719131",
    log: " || action Concat: 26143.72anass || ",
  },
  {
    id: "e0f6aafb-b108-4da1-a281-40ff61966ea7",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:50:57.827605",
    log: " || action Concat: 26147.4anass || ",
  },
  {
    id: "ec7684eb-dd68-4ef4-8adb-ff17663220c1",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:49:56.481833",
    log: " || action Concat: 26154.13anass || ",
  },
  {
    id: "87324375-d08b-41aa-92c9-177c17eef5a0",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:48:56.965270",
    log: " || action Concat: 26154.13anass || ",
  },
  {
    id: "052930b1-ce73-4795-b008-7b4edceef23a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:47:56.347514",
    log: " || action Concat: 26148.26anass || ",
  },
  {
    id: "b028f240-4237-4da6-86c6-100d89824d8e",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:46:56.268861",
    log: " || action Concat: 26159.18anass || ",
  },
  {
    id: "6515827f-3f9d-4888-988c-7768dbe0b5c4",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:45:56.268267",
    log: " || action Concat: 26150.48anass || ",
  },
  {
    id: "b1bbd205-e9ad-48cb-93c1-d2135800aafa",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:44:58.978442",
    log: " || action Concat: 26159.7anass || ",
  },
  {
    id: "e261c942-cf5a-425c-962f-6b9a50519fe2",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:43:56.035923",
    log: " || action Concat: 26150.47anass || ",
  },
  {
    id: "6d0408ef-c1ea-4d4b-b915-54217f18cd2f",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:42:56.078326",
    log: " || action Concat: 26155.26anass || ",
  },
  {
    id: "3536de90-0ff0-460e-987e-07604fb8e3ac",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:41:56.114704",
    log: " || action Concat: 26167.79anass || ",
  },
  {
    id: "b907721e-d7ee-459f-8ad5-b9ae1590b3e5",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:40:55.995633",
    log: " || action Concat: 26151.35anass || ",
  },
  {
    id: "7f2d18e0-9c63-4a05-8004-742356a444de",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:39:57.599159",
    log: " || action Concat: 26152.55anass || ",
  },
  {
    id: "1f2a3bfc-4073-4e4e-aee0-ab6310d88cf6",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:38:55.834745",
    log: " || action Concat: 26160.58anass || ",
  },
  {
    id: "1db59e19-9893-4437-a602-5c781d308785",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:37:56.793940",
    log: " || action Concat: 26177.15anass || ",
  },
  {
    id: "47feeb67-f912-4cd5-b06f-4c3a55c33217",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:36:55.572742",
    log: " || action Concat: 26163.98anass || ",
  },
  {
    id: "e531413c-1f03-4184-bbb3-e8f1d34b1505",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:35:56.265410",
    log: " || action Concat: 26156.55anass || ",
  },
  {
    id: "78025dd3-e348-4b91-826b-64241075a21a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:34:55.296549",
    log: " || action Concat: 26162.82anass || ",
  },
  {
    id: "a0cca7bd-4d61-4cc4-aaec-40f953d10955",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:33:55.272228",
    log: " || action Concat: 26151.7anass || ",
  },
  {
    id: "b578a8e4-3c6f-4a18-8554-b4378a4b6034",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:32:56.145957",
    log: " || action Concat: 26166.22anass || ",
  },
  {
    id: "4b2f76c5-f9c2-4900-9a7d-9b91b1cf2485",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:31:55.213911",
    log: " || action Concat: 26154.3anass || ",
  },
  {
    id: "d7608acd-e8bd-4450-84f0-cd3363962340",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:30:55.200521",
    log: " || action Concat: 26161.53anass || ",
  },
  {
    id: "7498b4cd-e137-4722-a2ac-7f21aa14989b",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:29:56.554529",
    log: " || action Concat: 26140.91anass || ",
  },
  {
    id: "5aca2440-d906-4146-9dd9-ee0697b0e94b",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:28:55.088400",
    log: " || action Concat: 26139.99anass || ",
  },
  {
    id: "c9ea81ec-2f23-4384-9e97-0a68286b8300",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:27:54.919537",
    log: " || action Concat: 26133.7anass || ",
  },
  {
    id: "f135f734-380c-4691-a504-af1a92d17cbc",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:26:54.883434",
    log: " || action Concat: 26128.87anass || ",
  },
  {
    id: "f282f0da-af0a-4594-8962-11c25c0f9912",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:25:54.861909",
    log: " || action Concat: 26128.87anass || ",
  },
  {
    id: "2b4e4490-7ae9-42fb-b8d1-08bf1cf9ccdc",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:24:54.581859",
    log: " || action Concat: 26126.42anass || ",
  },
  {
    id: "26761433-616a-41d8-8fe6-060363240b00",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:23:54.484001",
    log: " || action Concat: 26123.1anass || ",
  },
  {
    id: "cddcb212-b761-4bc2-bb06-ce08cad2131a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:22:54.489984",
    log: " || action Concat: 26134.02anass || ",
  },
  {
    id: "aa5fa3d2-2586-414f-8938-d12ce86aea6c",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:21:54.343030",
    log: " || action Concat: 26116.45anass || ",
  },
  {
    id: "ef945d46-2e7d-4188-b17f-41ba5d829632",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:20:56.501818",
    log: " || action Concat: 26117.72anass || ",
  },
  {
    id: "09c190d7-da0d-4fc8-bacf-6e3993f8a644",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:19:54.996080",
    log: " || action Concat: 26137.01anass || ",
  },
  {
    id: "51cb7137-5914-4a57-99cc-583ed5acb782",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:18:54.166658",
    log: " || action Concat: 26139.42anass || ",
  },
  {
    id: "2d78b7fd-cef4-404c-a8fb-0dfd79b56252",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:17:54.763940",
    log: " || action Concat: 26153.18anass || ",
  },
  {
    id: "779fb854-384e-4650-bbad-767382ea01a5",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:16:53.914487",
    log: " || action Concat: 26121.43anass || ",
  },
  {
    id: "b685e208-df54-4da9-bede-941b16128191",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:15:59.018350",
    log: " || action Concat: 26135.42anass || ",
  },
  {
    id: "646116e3-3f95-4d65-b669-2c1c938b8639",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:14:53.846477",
    log: " || action Concat: 26133.99anass || ",
  },
  {
    id: "a377dc3d-ffe7-4296-ad31-d1314633b2a2",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:13:54.302502",
    log: " || action Concat: 26102.47anass || ",
  },
  {
    id: "17269a34-134b-49f8-be69-ffdec0016f9b",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:12:54.057298",
    log: " || action Concat: 26099.72anass || ",
  },
  {
    id: "1f9d355b-2782-4e86-ae4d-cad43ee4ac36",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:11:53.405173",
    log: " || action Concat: 26093.11anass || ",
  },
  {
    id: "e8eba577-e440-47af-bd9b-d6f92a337b6c",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:10:53.304104",
    log: " || action Concat: 26093.12anass || ",
  },
  {
    id: "1c6578f1-2cb3-4c95-8bb8-719c67972b0a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:09:53.182670",
    log: " || action Concat: 26091.5anass || ",
  },
  {
    id: "7b0eceff-bbe3-456d-a7ba-947e70e7d840",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:08:54.105411",
    log: " || action Concat: 26087.25anass || ",
  },
  {
    id: "bc3c5e86-a8c8-49ba-ae82-52eae85bc8cb",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:07:53.096707",
    log: " || action Concat: 26087.19anass || ",
  },
  {
    id: "151eaaf3-b32d-4c98-88dc-e2fb5d44007c",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:06:53.392325",
    log: " || action Concat: 26087.2anass || ",
  },
  {
    id: "411cc931-95e0-409a-8c85-ac5330015ed5",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:05:52.936501",
    log: " || action Concat: 26087.2anass || ",
  },
  {
    id: "4c0632b6-aae5-44e8-82c8-9c545df31772",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:04:52.968404",
    log: " || action Concat: 26085.46anass || ",
  },
  {
    id: "65fd1f27-ec6d-40ae-a430-f61ea1444647",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:04:26.578958",
    log: " || action Concat: 26084.99anass || ",
  },
  {
    id: "6074b4b5-d957-4c77-88fc-337886a73509",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:03:25.601790",
    log: " || action Concat: 26082.66anass || ",
  },
  {
    id: "1d540921-8b4c-44ff-b1f3-df8da5c203d6",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:02:27.311230",
    log: " || action Concat: 26079.98anass || ",
  },
  {
    id: "4ed2fd4f-5db5-467d-aaa5-7098ceae2a7b",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:01:25.982527",
    log: " || action Concat: 26079.98anass || ",
  },
  {
    id: "3935b282-2092-44ba-b2f4-540aa5200cb9",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T14:00:25.382798",
    log: " || action Concat: 26079.98anass || ",
  },
  {
    id: "79321694-4f85-432a-ac6f-70acbc323426",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:59:25.204267",
    log: " || action Concat: 26079.99anass || ",
  },
  {
    id: "1222124a-845f-483d-880f-79e0961dd7dc",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:58:25.184094",
    log: " || action Concat: 26079.99anass || ",
  },
  {
    id: "f71799e2-65a3-4b8b-9a10-b4872fbd6f82",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:57:24.933134",
    log: " || action Concat: 26078.76anass || ",
  },
  {
    id: "c45f9e28-7ac6-404f-a540-004fa2c31908",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:56:25.876679",
    log: " || action Concat: 26076.59anass || ",
  },
  {
    id: "2d48baa5-0d73-44b5-9039-178945aff72f",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:55:25.695610",
    log: " || action Concat: 26071.79anass || ",
  },
  {
    id: "c3f51a21-a9af-4657-9764-f82e64ab46de",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:54:26.995516",
    log: " || action Concat: 26070.76anass || ",
  },
  {
    id: "32f45a98-4654-4073-8bda-77a8049725df",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:53:24.963118",
    log: " || action Concat: 26066.56anass || ",
  },
  {
    id: "7fe3597b-4a86-49eb-a9cd-0c32e5ffe9fc",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:52:34.093815",
    log: " || action Concat: 26066.57anass || ",
  },
  {
    id: "941f8ad3-93d9-4147-a27f-6209844c2aff",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:51:33.343410",
    log: " || action Concat: 26066.57anass || ",
  },
  {
    id: "2f82ba54-cf93-4f45-b1cd-2b6d04082672",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:50:33.185985",
    log: " || action Concat: 26066.56anass || ",
  },
  {
    id: "6d319ed3-463f-4263-b9cb-11815f590ddc",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:49:33.893542",
    log: " || action Concat: 26067.6anass || ",
  },
  {
    id: "5c35af07-bcd1-4012-b6d3-95592d669591",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:48:33.763060",
    log: " || action Concat: 26067.61anass || ",
  },
  {
    id: "93d23dbc-8bb1-4d15-bc4a-03ff8423c69a",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:47:32.898270",
    log: " || action Concat: 26069.99anass || ",
  },
  {
    id: "c30db336-ff3f-40fc-bbad-4e8570c793b1",
    bot_id: "731a4e89-5594-4aa5-adcb-ebd0a0e30d35",
    date: "2023-08-27T13:46:32.828723",
    log: " || action Concat: 26069.99anass || ",
  },
];
