export const SET_TEMPLATES = "SET_TEMPLATES";
export const ADD_CARD = "ADD_CARD";
export const SET_CARDS = "SET_CARDS";
export const SET_ARROWS = "SET_ARROWS";
export const REMOVE_CARD = "REMOVE_CARD";
export const REMOVE_ARROWS_FROM_CARD = "REMOVE_ARROWS_FROM_CARD";
export const REMOVE_ARROW_TO_INPUT = "REMOVE_ARROW_TO_INPUT";
export const REMOVE_DRAFT = "REMOVE_DRAFT";
export const FOCUS_CARD = "FOCUS_CARD";
export const REMOVE_FOCUS_CARD = "REMOVE_FOCUS_CARD";
export const SET_DRAG_OVER_ITEM = "SET_DRAG_OVER_ITEM";
export const BUILD_ARROW = "BUILD_ARROW";
export const SET_TOP_LEFT = "SET_TOP_LEFT";
export const SET_FIELD_VALUE = "SET_FIELD_VALUE";
export const SET_FIELD_OPTIONS = "SET_FIELD_OPTIONS";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_USER_INFO = "SET_USER_INFO";
export const SHOW_HIDE_LOGS = "SHOW_HIDE_LOGS";
export const SET_BOT_STATUS = "SET_BOT_STATUS";
export const SET_TEMPLATES_TYPE = "SET_TEMPLATES_TYPE";
export const INVALIDATE_ARROW = "INVALIDATE_ARROW";
export const WORKSPACE_HAS_CHANGES = "WORKSPACE_HAS_CHANGES";
export const WORKSPACE_HAS_OPTIONAL_CHANGES = "WORKSPACE_HAS_OPTIONAL_CHANGES";
export const SET_INPUT_TYPES_COLORS = "SET_INPUT_TYPES_COLORS";
export const LOG_OUT = "LOG_OUT";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_TRIGGER_DETAILS = "SET_TRIGGER_DETAILS";
export const NEW_FIELD_OPTIONS = "NEW_FIELD_OPTIONS";

export const setTemplates = (list) => ({ type: SET_TEMPLATES, list });
export const addCard = (card) => ({ type: ADD_CARD, card });
export const setCards = (list) => ({ type: SET_CARDS, list });
export const setArrows = (list) => ({ type: SET_ARROWS, list });
export const removeCard = (id) => ({ type: REMOVE_CARD, id });
export const removeArrowsFromCard = (id) => ({
  type: REMOVE_ARROWS_FROM_CARD,
  id,
});
export const removeDraft = (retainBotData) => ({
  type: REMOVE_DRAFT,
  retainBotData,
});
export const focusCard = (id) => ({ type: FOCUS_CARD, id });
export const removeFocusCard = () => ({ type: REMOVE_FOCUS_CARD });
export const setDragOverItem = (id) => ({
  type: SET_DRAG_OVER_ITEM,
  id,
});
export const removeArrowToInput = (details) => ({
  type: REMOVE_ARROW_TO_INPUT,
  details,
});
export const buildArrow = (details) => ({ type: BUILD_ARROW, details });
export const setTopLeft = (details) => ({ type: SET_TOP_LEFT, details });
export const setFieldValue = (details) => ({ type: SET_FIELD_VALUE, details });
export const setFieldOptions = (details) => ({
  type: SET_FIELD_OPTIONS,
  details,
});
export const setUserInfo = (userInfo) => ({ type: SET_USER_INFO, userInfo });
export const setAuthenticated = (authenticated) => ({
  type: SET_AUTHENTICATED,
  authenticated,
});
export const showHideLogs = () => ({ type: SHOW_HIDE_LOGS });
export const setBotStatus = (status) => ({ type: SET_BOT_STATUS, status });
export const invalidateArrow = (details) => ({
  type: INVALIDATE_ARROW,
  details,
});
export const workspaceHasChanges = (value) => ({
  type: WORKSPACE_HAS_CHANGES,
  value,
});
export const workspaceHasOptionalChanges = (value) => ({
  type: WORKSPACE_HAS_OPTIONAL_CHANGES,
  value,
});
export const setTypesColors = (list) => ({
  type: SET_INPUT_TYPES_COLORS,
  list,
});
export const logOut = () => ({ type: LOG_OUT });
export const setCurrencies = (details) => ({
  type: SET_CURRENCIES,
  details,
});
export const setTriggerDetails = (details) => ({
  type: SET_TRIGGER_DETAILS,
  details,
});
export const setNewFieldOptions = (details) => ({
  type: NEW_FIELD_OPTIONS,
  details,
});
