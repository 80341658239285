import React from "react";
import { Spinner } from "components/ui";
import classNames from "classnames";

const DefaultLoading = ({
  loading,
  children,
  spinnerClass,
  className,
  asElement: Component,
  customLoader,
  size,
}) => {
  return loading ? (
    <Component
      className={classNames(
        !customLoader && "flex items-center justify-center h-full",
        className
      )}
    >
      {customLoader ? (
        <>{customLoader}</>
      ) : (
        <Spinner className={spinnerClass} size={size || 40} />
      )}
    </Component>
  ) : (
    <>{children}</>
  );
};

const CoveredLoading = ({
  loading,
  children,
  spinnerClass,
  className,
  asElement: Component,
  customLoader,
  size,
}) => {
  return (
    <Component className={classNames(loading ? "relative" : "", className)}>
      {children}
      {loading && (
        <div className="w-full h-full bg-white dark:bg-gray-800 dark:bg-opacity-60 bg-opacity-50 absolute inset-0" />
      )}
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          {customLoader ? (
            <>{customLoader}</>
          ) : (
            <Spinner className={spinnerClass} size={size || 40} />
          )}
        </div>
      )}
    </Component>
  );
};

const Loading = ({
  loading = false,
  type = "default",
  asElement = "div",
  ...props
}) => {
  switch (type) {
    case "default":
      return (
        <DefaultLoading loading={loading} asElement={asElement} {...props} />
      );
    case "cover":
      return (
        <CoveredLoading loading={loading} asElement={asElement} {...props} />
      );
    default:
      return (
        <DefaultLoading loading={loading} asElement={asElement} {...props} />
      );
  }
};

export default Loading;
