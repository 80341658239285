import React from "react";
import useAuthority from "utils/hooks/useAuthority";

const AuthorityCheck = (props) => {
  const { userAuthority = [], authority = [], children } = props;

  const roleMatched = useAuthority(userAuthority, authority);

  return roleMatched ? children : <></>;
};

export default AuthorityCheck;
