import React from "react";
import { FormContextProvider, FormContextConsumer } from "../context";
import { useConfig } from "../../ConfigProvider";
import { SIZES, LAYOUT } from "../../utils/constant";
import classNames from "classnames";

const FormContainer = ({
  children,
  labelWidth = 100,
  layout = LAYOUT.VERTICAL,
  size = SIZES.MD,
  className,
}) => {
  const { controlSize } = useConfig();

  const contextValue = {
    labelWidth,
    layout,
    size: size || controlSize,
  };

  return (
    <FormContextProvider value={contextValue}>
      <FormContextConsumer>
        {(context) => {
          return (
            <div
              className={classNames(
                "form-container",
                context.layout,
                className
              )}
            >
              {children}
            </div>
          );
        }}
      </FormContextConsumer>
    </FormContextProvider>
  );
};

export default FormContainer;
