import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  apiAdminSignIn,
  apiSignIn,
  apiSignOut,
  apiSignUp,
} from "services/AuthService";
import {
  onSignInSuccess,
  onSignOutSuccess,
  rememberUsername,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import {
  CURRENCIES_STORE_NAME,
  CUSTOM_URLS_CALLS_STORE_NAME,
  REDIRECT_URL_KEY,
  REFRESH_TOKEN_STORE_NAME,
  SUBSCRIPTION_STORE_NAME,
  TEMPLATES_STORE_NAME,
  TOKEN_STORE_NAME,
} from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { toast, Notification, Alert } from "components/ui";
import { decodeTokenAndStoreDetails } from "utils/utils";
import { logOut } from "actions/mainActions";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { signedIn, username, isAdmin } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async (values) => {
    try {
      const resp = !values.isAdmin
        ? await apiSignIn({
            username_or_email: values.username,
            password: values.password,
          })
        : await apiAdminSignIn({
            username: values.username,
            password: values.password,
          });
      if (resp.data?.access_token) {
        const decoded = decodeTokenAndStoreDetails(resp.data);

        dispatch(onSignInSuccess(values.isAdmin));
        values.rememberMe
          ? dispatch(rememberUsername(values.username))
          : dispatch(rememberUsername());
        if (decoded.sub) {
          dispatch(
            setUser({
              avatar: "",
              userName: decoded.sub,
              authority: [decoded.authority],
              name: decoded.name,
              email: decoded.email,
            })
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(
          redirectUrl
            ? redirectUrl
            : !values.isAdmin
            ? appConfig.authenticatedEntryPathUser
            : appConfig.authenticatedEntryPathAdmin
        );
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      let message =
        "The credentials provided are incorrect. Please double-check and try again.";
      if (errors?.response?.status === 403) {
        message =
          "Your account has been blocked. Please contact us for further assistance.";
      }
      if (errors?.response?.status === 428) {
        message =
          "Your account is not yet approved. Please verify your email address to complete the approval process. Check your spam folder or contact us if needed.";
      }
      return {
        status: "failed",
        message,
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        toast.push(
          <Alert
            title="Please check your inbox for a verification email, and follow the link
          to activate your account."
            type="info"
            closable
            duration={0}
            rounded={false}
          ></Alert>,
          {
            transitionType: "fade",
            placement: "top-center",
          }
        );
        toast.push(<Notification title="Account created!" type="success" />);
        navigate(appConfig.userUnAuthenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message:
          errors?.response?.data?.error ||
          "Not valid, please resubmit new details!",
      };
    }
  };

  const handleSignOut = (newSignUp) => {
    localStorage.removeItem(TOKEN_STORE_NAME);
    localStorage.removeItem(REFRESH_TOKEN_STORE_NAME);
    localStorage.removeItem(SUBSCRIPTION_STORE_NAME);
    localStorage.removeItem(TEMPLATES_STORE_NAME);
    localStorage.removeItem(CURRENCIES_STORE_NAME);
    localStorage.removeItem(CUSTOM_URLS_CALLS_STORE_NAME);
    dispatch(logOut());
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    if (newSignUp) {
      navigate("/sign-up");
    } else {
      navigate(
        isAdmin ? appConfig.adminUnAuthenticatedEntryPath : appConfig.tourPath
      );
    }
  };

  const signOut = async (newSignUp = false) => {
    await apiSignOut();
    handleSignOut(newSignUp);
  };

  return {
    authenticated: signedIn,
    login: username,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
