import React from "react";
import classNames from "classnames";

const Table = React.forwardRef(
  (
    {
      borderlessRow = false,
      children,
      className,
      hoverable = true,
      compact = false,
      oveerflow = true,
      asElement: Component = "table",
      ...rest
    },
    ref
  ) => {
    const tableClass = classNames(
      Component === "table" ? "table-default" : "table-flex",
      hoverable && "table-hover",
      compact && "table-compact",
      borderlessRow && "borderless-row",
      className
    );

    return (
      <div className={classNames(oveerflow && "overflow-x-auto")}>
        <Component className={tableClass} {...rest} ref={ref}>
          {children}
        </Component>
      </div>
    );
  }
);

export default Table;
