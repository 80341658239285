import wildCardSearch from "utils/wildCardSearch";
import sortBy from "utils/sortBy";
import paginate from "utils/paginate";

export default function usersFakeApi(server, apiPrefix) {
  server.post(`${apiPrefix}/users`, (schema, { requestBody }) => {
    const body = JSON.parse(requestBody);
    const { pageIndex, pageSize, sort, query } = body;
    const { order, key } = sort;
    const users = !body.filterData.status
      ? schema.db.usersData
      : schema.db.usersData.where({ status: body.filterData.status });
    const sanitizeUsers = users.filter((elm) => typeof elm !== "function");
    let data = sanitizeUsers;
    let total = users.length;

    if (key && order) {
      if (key !== "lastOnline") {
        data.sort(sortBy(key, order === "desc", (a) => a.toUpperCase()));
      } else {
        data.sort(sortBy(key, order === "desc", parseInt));
      }
    }

    if (query) {
      data = wildCardSearch(data, query);
      total = data.length;
    }

    data = paginate(data, pageSize, pageIndex);

    const responseData = {
      data: data,
      total: total,
    };
    return responseData;
  });

  server.get(`${apiPrefix}/users/statistic`, (schema) => {
    const users = schema.db.usersData;
    return {
      totalUsers: {
        value: users.length,
      },
      activeUsers: {
        value: users.where({ status: "active" }).length,
      },
      newUsers: {
        value: users.where({ status: "pending" }).length,
      },
    };
  });

  server.get(`${apiPrefix}/users/details`, (schema, { queryParams }) => {
    const id = queryParams.id;
    const user = schema.db.usersData.find(id);
    return user;
  });

  server.del(`${apiPrefix}/users/delete`, (schema, { requestBody }) => {
    const { id } = JSON.parse(requestBody);
    schema.db.usersData.remove({ id });
    return {};
  });

  server.put(`${apiPrefix}/users`, (schema, { requestBody }) => {
    const data = JSON.parse(requestBody);
    const { id } = data;
    schema.db.usersData.update({ id }, data);
    return {};
  });
}
